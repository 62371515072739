import { CircularProgress } from '@mui/material'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'

export const RenderSkills = ({ skillId }) => {
  const [responseData, setResponseData] = useState('')
  const [loading, setLoading] = useState(true)

  const allKeySkills = useSelector(state => state.keySkillsReducer.keySkills)

  useEffect(() => {
    if (allKeySkills) {
      const findSkill = allKeySkills.find(e => e._id === skillId)
      if (!findSkill) {
        setResponseData('---')
        setLoading(false)
      } else {
        setResponseData(findSkill.KeySkill)
        setLoading(false)
      }
    }
  }, [])

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : loading ? (
        <CircularProgress />
      ) : (
        <li style={{ fontSize: '18px' }}>{responseData}</li>
      )}
    </>
  )
}
