import { eraseSingleCanditateData, modifySingleCanditateData, saveSingleCanditateData,startGettingSingleCandidateData} from "../../../constants/candidates_constants"
import { logoutUser} from "../../../constants/common_constants"


const INITIAL_STATE = {
    isLoading:true,
    singleCandidate:{},
}


const singleCandidateReducer = (state = INITIAL_STATE,action) => {

    const {type, payload} = action

    switch (type) {
        case saveSingleCanditateData:
            return {
                ...state,
                isLoading:false,
                singleCandidate:payload
            }
        case startGettingSingleCandidateData:
            return {
                ...state,
                isLoading:true,
            }
        case logoutUser:
            return {
                ...state,
                singleCandidate:{},
                isLoading:true,
            }
        case modifySingleCanditateData:
            return{
                ...state,
                singleCandidate:{...state.singleCandidate, payload },
            }
        case eraseSingleCanditateData:
            return{
                ...state,
                singleCandidate:{},
                isLoading:true,
            }
        default:
            return state
    }
}


export default singleCandidateReducer





















