import { toast } from "react-toastify"

export const displaySuccessToast = (message) =>{
    
    toast.success(message, {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true
      })

}


export const displayErrorToast = (message) =>{
    
    toast.error(message, {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true
      })

}