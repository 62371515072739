import React, { useEffect, useState } from 'react'
import logo from '../assets/images/apex-logo-for-loginpage.png'
import {
  Paper,
  Stack,
  TextField,
  Button,
  CircularProgress
} from '@mui/material'
import { allStyles } from '../allStyles'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import { saveTokens, userState } from '../redux/actions/userActions'
import { ToastContainer } from 'react-toastify'
import { displayErrorToast, displaySuccessToast } from '../helper/toast_notification_function'

export const Login = () => {
  const dispatch = useDispatch()
  const [loginData, setLoginData] = useState({})
  const [processLoading, setProcessLoading] = useState(false)



  let login = e => {
    e.preventDefault()
    if (!loginData.Username || !loginData.Password) {
      alert('Please fill the input fields!')
    } else {
      setProcessLoading(true)
      axios
        .post(
          process.env.REACT_APP_ADMIN_LOGIN_URL,
          loginData
        )

        .then(async res => {
          localStorage.setItem(
            'refreshToken',
            await res.data['tokens']['refreshToken']
          )
          localStorage.setItem('accessToken', await res.data['tokens']['accessToken'])

          let tokens = {
            accessToken: await res.data['tokens']['accessToken'],
            refreshToken: await res.data['tokens']['refreshToken']
          }

          displaySuccessToast('Logged in Successfully!')

          saveTokens(dispatch, tokens)
          setProcessLoading(false)
          e.target.reset()
          userState(dispatch, true)
        })

        .catch(error => {
          displayErrorToast(error.response.data.message[0])
          setProcessLoading(false)
        })
    }
  }

  useEffect(() => {
    setLoginData({})
  }, [])

  return (
    <Stack
      justifyContent='center'
      alignItems='center'
      direction='column'
      sx={allStyles.loginPage}
    >
      <Paper
        square
        elevation={2}
        children={
          <Stack
            sx={allStyles.loginBox}
            alignItems='center'
            justifyContent='center'
          >
            <Stack
              alignItems='center'
              spacing={3}
              direction='column'
              component='form'
              sx={{
                '& > :not(style)': { width: '35ch' },
                px: 4
              }}
              noValidate
              autoComplete='off'
              onSubmit={login}
            >
              <Stack sx={{ py: 3 }} alignItems='center'>
                <img width='250px' src={logo} />
              </Stack>


              {/* <Stack>
                <Typography
                  variant='h5'
                  align='center'
                  sx={allStyles.loginText}
                >
                  Log in
                </Typography>
              </Stack> */}
              <Stack spacing={3}>
                <Stack spacing={2}>
                  <TextField
                    fullWidth
                    // id="outlined-basic"
                    type='text'
                    id='Username'
                    label='User Name'
                    variant='outlined'
                    onChange={e =>
                      setLoginData({ ...loginData, Username: e.target.value })
                    }
                  />
                  <TextField
                    // id="outlined-basic"
                    type='password'
                    id='Password'
                    label='Password'
                    variant='outlined'
                    onChange={e =>
                      setLoginData({ ...loginData, Password: e.target.value })
                    }
                  />
                </Stack>
                {/* <Stack direction='row' justifyContent='center'>
                  <Button
                    sx={allStyles.loginButton}
                    size='large'
                    variant='outlined'
                    // color="#1D3557"
                    type='submit'
                  >
                    Login
                  </Button>
                </Stack> */}

                <Stack
                  direction='row'
                  sx={{ my: 3, position: 'relative' }}
                  justifyContent='center'
                  alignItems='center'
                >
                  <Button
                    type='submit'
                    disabled={processLoading}
                    sx={allStyles.loginButton}
                    variant='outlined'
                    color='primary'
                    size='large'
                  >
                    {processLoading ? '' : 'Log in'}
                  </Button>
                  {processLoading ? (
                    <Stack sx={{ position: 'absolute' }}>
                      {' '}
                      <CircularProgress size={20} disableShrink />
                    </Stack>
                  ) : null}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        }
      />

      <ToastContainer />
    </Stack>
  )
}
