import { logoutUser} from "../../../constants/common_constants"
import { eraseSingleEmployerDataType, modifySingleEmployerData, saveSingleEmployerData,startGettingSingleEmployerData } from "../../../constants/employers_constant"


const INITIAL_STATE = {
    isLoading:true,
    singleEmployer:{},
}


const singleEmployerReducer = (state = INITIAL_STATE,action) => {

    const {type, payload} = action

    switch (type) {
        case saveSingleEmployerData:
            return {
                ...state,
                isLoading:false,
                singleEmployer:payload
            }
        case startGettingSingleEmployerData:
            return {
                ...state,
                isLoading:true,
            }
        case logoutUser:
            return {
                ...state,
                singleEmployer:{},
                isLoading:true,
            }
        case modifySingleEmployerData:
            return{
                ...state,
                singleEmployer:{...state.singleEmployer, payload },
            }
        case eraseSingleEmployerDataType:
            return{
                ...state,
                singleEmployer:{},
                isLoading:true,
            }
        default:
            return state
    }
}


export default singleEmployerReducer





















