import { logoutUser} from "../../../constants/common_constants"
import { eraseSingleEmplJobAdType, eraseSingleJobAdType, modifySingleJobAdData, saveSingleJobAdData, startGettingSingleJobAdData } from "../../../constants/job_ads_constants"


const INITIAL_STATE = {
    isLoading:true,
    singleJobAd:{},
}


const singleJobAdReducer = (state = INITIAL_STATE,action) => {

    const {type, payload} = action

    switch (type) {
        case saveSingleJobAdData:
            return {
                ...state,
                isLoading:false,
                singleJobAd:payload
            }
        case startGettingSingleJobAdData:
            return {
                ...state,
                isLoading:true,
            }
        case logoutUser:
            return {
                ...state,
                singleJobAd:{},
                isLoading:true,
            }
        case modifySingleJobAdData:
            return{
                ...state,
                singleJobAd:{...state.singleJobAd, payload },
            }
        case eraseSingleJobAdType:
            return{
                ...state,
                singleJobAd:{},
                isLoading:true,
            }
        default:
            return state
    }
}


export default singleJobAdReducer





















