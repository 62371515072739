export const saveCandidates = 'SAVE_CANDIDATES'
export const startGettingCandidatesData = 'START_GETTING_CANDIDATES_DATA'

export const startGettingSingleCandidateData = 'START_GETTING_SINGLE_CANDIDATE_DATA'
export const saveSingleCanditateData = 'SAVE_SINGLE_CANDIDATE_DATA'
export const modifySingleCanditateData = 'MODIFY_SINGLE_CANDIDATE_DATA'


export const deleteSingleCanditateData = 'DELETE_SINGLE_CANDITATE_DATA'
export const eraseSingleCanditateData = 'ERASE_SINGLE_CANDIDATE_DATA'
