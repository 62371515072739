import { logoutUser} from "../../../constants/common_constants"
import { deleteSingleJobAdData, modifySingleJobAdData, saveJobAds, startGettingJobAdsData } from "../../../constants/job_ads_constants"



const INITIAL_STATE = {
    isLoading:true,
    jobAds:[],
}


const jobAdsReducer = (state = INITIAL_STATE,action) => {

    const {type, payload} = action

    switch (type) {
        case saveJobAds:
            return {
                ...state,
                isLoading:false,
                jobAds:payload.filter((e, i) => e.isDeleted !== true)
            }
        case startGettingJobAdsData:
            return {
                ...state,
                isLoading:true,
            }
        case logoutUser:
            return {
                ...state,
                jobAds:[],
                isLoading:true,
            }
        case modifySingleJobAdData:
                let findObj = state.jobAds.find((e, i) => e._id === payload._id)
                let findInd = state.jobAds.indexOf(findObj)
                // let newCandidatesArray = state.candidates
                state.jobAds[findInd] = payload
                return {
                  ...state,
                  jobAds: [...state.jobAds],
                  isLoading: false
                }
        case deleteSingleJobAdData:
                let newDataArray = state.jobAds.filter((e, i) => e._id !== payload && e.isDeleted !== true)
                return {
                  ...state,
                  jobAds: newDataArray,
                  isLoading: false
                }
        default:
            return state
    }
}


export default jobAdsReducer