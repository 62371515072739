import React, { useEffect, useState } from 'react'
import {
  Box,
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Paper,
  TablePagination,
  Button
} from '@mui/material'
import { TableBodyDataSkeleton } from '../../assets/TableBodyDataSkeleton'
import { PaginationSkeleton } from '../../assets/PaginationSkeleton'
import { RefressButton } from '../../assets/buttons/RefressButton'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import {
  deleteSingleEmployer,
  eraseSingleEmployerData,
  getAllEmployers,
  getSingleEmployer,
  modifySingleEmployer
} from '../../redux/actions/employersActions'
import { allStyles } from '../../allStyles'
import { updateEmployerSubValidityApi } from '../../api/employersApi'
import { ViewEmployerDetails } from './ViewEmployerDetails'
import { actionButtonCaptions } from '../../helper/action_button_captions'
import { formatDate } from '../../helper/date_format_function'
import { ExtendPremiumValidity } from './ExtendPremiumValidity'
import { displayErrorToast, displaySuccessToast } from '../../helper/toast_notification_function'
import { useNavigate } from 'react-router-dom'
import { NoDataSetMessage } from '../../components/NoDataSetMessage'


const columns = [
  {
    id: 'serialNumber',
    label: 'S#',
    minWidth: 20,
    align: 'center',
    sortAction: false
  },
  {
    id: 'ContactPersonName',
    label: 'Contact Person Name',
    minWidth: 200,
    align: 'center',
    sortAction: false
  },
  {
    id: 'Username',
    label: 'User Name',
    minWidth: 100,
    align: 'center',
    sortAction: true
  },
  {
    id: 'City',
    label: 'City',
    minWidth: 100,
    align: 'center',
    sortAction: true
  },
  {
    id: 'organizationEmail',
    label: 'Organization Email',
    minWidth: 50,
    align: 'center',
    sortAction: true
  },
  {
    id: 'organizationPhoneNumber',
    label: 'Phone Number',
    minWidth: 50,
    align: 'center',
    sortAction: false
  },
  {
    id: 'createdAt',
    label: 'Created Date',
    minWidth: 170,
    align: 'center',
    sortAction: true
  },
  {
    id: 'subscriptionType',
    label: 'Subscription Type',
    minWidth: 170,
    align: 'center',
    sortAction: true
  },
  {
    id: 'SubscriptionValidity',
    label: 'Subscription Validity',
    minWidth: 170,
    align: 'center',
    sortAction: true
  },
  {
    id: 'actions',
    label: 'Actions',
    minWidth: 170,
    align: 'center',
    sortAction: false
  }
]

export const Employers = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [open, setOpen] = useState(false)

  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(true)
  const [orderData, setOrderData] = useState('ASC')

  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const [extendvalidityModal, setExtendValidityModal] = React.useState(false)
  const [selectedRowExtendValidity, setSelectedRowExtendValidity] = React.useState(null)

  const allEmployers = useSelector(state => state.employersReducer)
  const accessToken = useSelector(state => state.userTokensReducer.tokens.accessToken)

  const sortingColumns = col => {
    if (orderData === 'ASC') {
      const sorted = [...rows].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      )
      setRows([...sorted])
      setOrderData('DSC')
    } else {
      const sorted = [...rows].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      )
      setRows([...sorted])
      setOrderData('ASC')
    }
  }

  useEffect(() => {
    setLoading(allEmployers.isLoading)
    setRows(allEmployers.employers)
  }, [])

  useEffect(() => {
    setLoading(allEmployers.isLoading)
    setRows(allEmployers.employers)
  }, [allEmployers])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  // View Details Modal Close Handle through modal cross button
  const closeClickHandle = () => {
    setOpen(false)
    eraseSingleEmployerData(dispatch)
  }
  
  const refreshHandle = () => {
    getAllEmployers(dispatch, accessToken,navigate)
  }
  
  // View Details Modal Open Handle 
  const viewDetailsHandle = selectedData => {
    setOpen(true)
    getSingleEmployer(dispatch, accessToken, selectedData._id,navigate)
  }
  
  const actionClickHandle = (buttonCaption, toSetStatus, selectedRow) => {
    if (buttonCaption === 'isDeleted') {
    deleteSingleEmployer(dispatch, accessToken, selectedRow)
    closeClickHandle()
    } else {
      let dataForAction = selectedRow
      dataForAction[buttonCaption] = toSetStatus
      modifySingleEmployer(
        dispatch,
        accessToken,
        selectedRow._id,
        buttonCaption,
        dataForAction,
        navigate
      )
    }
  }



  // Extend Validity Modal
const openExtendValidityModal = (selectedRow) => {
    const newObj = {
      id:selectedRow._id,
      SubscriptionValidity:selectedRow.SubscriptionValidity
    }
    setSelectedRowExtendValidity(newObj)
    setExtendValidityModal(true)
  }

  const closeExtendValidityModal = () => {
    setExtendValidityModal(false)
    setSelectedRowExtendValidity(null)
  }

  const extendValidity = (upadtedValidityData) => {
    const dateToSend = {SubscriptionValidity:upadtedValidityData.SubscriptionValidity}
    updateEmployerSubValidityApi(accessToken,upadtedValidityData.id,dateToSend,dispatch,navigate).then(()=>{
      displaySuccessToast('Validity extended successfully!')
      getAllEmployers(dispatch, accessToken,navigate)
    })
    .catch((err)=>{
      displayErrorToast(err.response.data.message[0])
    })
  }





  return (
    <Box>
      <Stack
        sx={{ px: 1, mb: 2 }}
        direction='row'
        alignItems='center'
        justifyContent='space-between'
      >
        <Stack>
          <Typography variant='h4'>Employers</Typography>
        </Stack>

        <Stack spacing={3} direction='row' alignItems='center'>
          <RefressButton disabled={loading} onClickHandle={refreshHandle} />
        </Stack>
      </Stack>

      <Stack>
        <Paper sx={{ width: '100%' }}>
          <TableContainer sx={{ maxHeight: 650 }}>
            <Table stickyHeader aria-label='sticky table'>
              <TableHead>
                <TableRow>
                  {columns.map((column, i) => (
                    <TableCell
                      key={i}
                      align={column.align}
                      style={{
                        ...allStyles.tableHeadCells,
                        width: column.minWidth
                      }}
                    >
                      {column.sortAction && !loading ? (
                        <Button
                          disabled={loading}
                          sx={{ textTransform: 'none' }}
                          onClick={() => sortingColumns(column.id)}
                        >
                          {column.label}
                        </Button>
                      ) : (
                        column.label
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              {loading ? (
                <TableBodyDataSkeleton numberOfColumns={columns.length} />
              ) : rows.length !==0 ? (
                <TableBody>
                  {rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => {
                      return (
                        <TableRow hover role='checkbox' tabIndex={-1} key={i}>
                          {columns.map((column, ind) => {
                            const value = row[column.id]
                            return column.id === 'serialNumber' ? (
                              <TableCell key={ind} align={column.align}>
                                {page < 1
                                  ? i + 1
                                  : page * rowsPerPage + (i + 1)}
                              </TableCell>
                            ) : column.id === 'createdAt' ? (
                              <TableCell key={ind} align={column.align}>
                                {formatDate(value)}
                              </TableCell>
                            ) : column.id === 'ContactPersonName' ? (
                              <TableCell key={ind} align={column.align}>
                                {`${row.contactPerson.FirstName} ${row.contactPerson.LastName}`}
                              </TableCell>
                            ) : column.id === 'actions' ? (
                              <TableCell key={ind} align={column.align}>
                                <Stack
                                  direction='row'
                                  spacing={1}
                                  justifyContent='start'
                                  alignItems='center'
                                >
                                  <Stack direction='row'
                                        spacing={3}
                                        >
                                          <Button
                                      variant='outlined'
                                      color='info'
                                      sx={allStyles.actionButtons}
                                      onClick={() => viewDetailsHandle(row)}
                                      // aria-label='viewDetails'
                                    >
                                     {actionButtonCaptions.viewDetailsButton}
                                    </Button>
                                    <Button
                                      variant='contained'
                                      color='error'
                                      sx={allStyles.actionButtons}
                                      onClick={() =>
                                        actionClickHandle(
                                          'isDeleted',
                                          true,
                                          row
                                        )
                                      }
                                    >
                                      {actionButtonCaptions.deleteButton}
                                    </Button>
                                  </Stack>
                                  <Stack>
                                    {row.isApproved ? (
                                      <Stack
                                        direction='row'
                                        spacing={3}
                                        sx={{ mx: 2, my: 1 }}
                                      >
                                        {row.isSuspended ? (
                                          <Button
                                            sx={allStyles.actionButtons}
                                            variant='contained'
                                            color='info'
                                            onClick={() =>
                                              actionClickHandle(
                                                'isSuspended',
                                                false,
                                                row
                                              )
                                            }
                                          >
                                            {actionButtonCaptions.activateSuspend}
                                          </Button>
                                        ) : (
                                          <Stack direction='row' spacing={3}>
                                            <Button
                                              sx={allStyles.actionButtons}
                                              variant='contained'
                                              color='info'
                                              onClick={() =>
                                                actionClickHandle(
                                                  'isSuspended',
                                                  true,
                                                  row
                                                )
                                              }
                                            >
                                              {actionButtonCaptions.suspendButton}
                                            </Button>
                                            {row.isTemporarySuspended ? (
                                              <Button
                                                sx={allStyles.actionButtons}
                                                variant='outlined'
                                                color='warning'
                                                onClick={() =>
                                                  actionClickHandle(
                                                    'isTemporarySuspended',
                                                    false,
                                                    row
                                                  )
                                                }
                                              >
                                               {actionButtonCaptions.activateTempSuspend}
                                              </Button>
                                            ) : (
                                              <Button
                                                sx={allStyles.actionButtons}
                                                variant='contained'
                                                color='warning'
                                                onClick={() =>
                                                  actionClickHandle(
                                                    'isTemporarySuspended',
                                                    true,
                                                    row
                                                  )
                                                }
                                              >
                                                {actionButtonCaptions.tempSuspendButton}
                                              </Button>
                                            )}
                                          </Stack>
                                        )}
                                      </Stack>
                                    ) : (
                                      <Stack
                                        direction='row'
                                        spacing={3}
                                        sx={{ mx: 2, my: 1 }}
                                      >
                                        <Button
                                          sx={allStyles.actionButtons}
                                          variant='contained'
                                          color='success'
                                          onClick={() =>
                                            actionClickHandle(
                                              'isApproved',
                                              true,
                                              row
                                            )
                                          }
                                        >
                                          {actionButtonCaptions.approveButton}
                                        </Button>
                                      </Stack>
                                    )}
                                  </Stack>
                                  <Stack direction='row'
                                        spacing={3}
                                        sx={{ mx: 2, my: 1 }}>
                                  </Stack>
                                </Stack>
                              </TableCell>
                            ) : column.id === 'isApproved' ? (
                              <TableCell key={ind} align={column.align}>
                                {value === false ? 'Not Approved' : 'Approved'}
                              </TableCell>
                            ) : column.id === 'SubscriptionValidity' ?(
                              <TableCell key={ind} align={column.align}>
                                <Stack spacing={2} alignItems='center'>
                                <Stack>
                                {value ? formatDate(value) : '--'}
                                </Stack>
                                {row.subscriptionType === 'premium' && <Stack direction='row'>
                                <Button
                                      variant='outlined'
                                      color='success'
                                      sx={allStyles.actionButtons}
                                      onClick={() =>
                                        openExtendValidityModal(row)
                                      }
                                    >
                                     Extend
                                    </Button>
                                </Stack>}
                                </Stack>
                              </TableCell>
                            ) : (
                              <TableCell key={ind} align={column.align}>
                                {value}
                              </TableCell>
                            )
                          })}
                        </TableRow>
                      )
                    })}
                </TableBody>
              ) : <NoDataSetMessage lengthOfColumns={columns.length} /> }
            </Table>
          </TableContainer>

          {loading ? (
            <PaginationSkeleton />
          ) : (
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component='div'
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Paper>
      </Stack>
      <ExtendPremiumValidity open={extendvalidityModal} closeClickHandle={closeExtendValidityModal} editState={selectedRowExtendValidity} submitDetails={extendValidity} />
      <ViewEmployerDetails
        open={open}
        closeClickHandle={closeClickHandle}
      />
      <ToastContainer />
    </Box>
  )
}
