import axios from 'axios'
import { isJwtExpired } from 'jwt-check-expiration'
import { displayErrorToast } from '../helper/toast_notification_function'
import { saveTokens, userState } from '../redux/actions/userActions'

const apiUrl = process.env.REACT_APP_ADMIN_API

const axiosHandle = token => {
  // console.log("axiosHandle token",token)
  return axios.create({
    baseURL: apiUrl,
    headers: {
      ...axios.defaults.headers,
      Authorization: `Bearer ${
        token ? token : localStorage.getItem('accessToken')
      }`
    }
  })
}




export const apiHandle = (token, dispatch, navigate) => {
 

  if (isJwtExpired(token) === false) {
    return axiosHandle(token)
  } else if (isJwtExpired(token) === true) {
    if (isJwtExpired(localStorage.getItem('refreshToken')) === false) {
      const refreshToken = {
        refreshToken: localStorage.getItem('refreshToken')
      }
      axiosHandle(token)
        .post('renewAccessToken', refreshToken)
        .then(async res => {
          const newAccessToken = await res.data['NewAccessToken']
          // console.log('renew token response',newAccessToken)
          localStorage.setItem('accessToken', newAccessToken)
          let bothtokens = {
            accessToken: newAccessToken,
            refreshToken: localStorage.getItem('refreshToken')
          }
          saveTokens(dispatch, bothtokens)
          return axiosHandle(newAccessToken)
        })

        .catch(error => {
          displayErrorToast(error.response.data.message[0])
        })
    } else {
      userState(dispatch, false)
      navigate('/')
    }
  } else {
  }
}

// export const apiHandle = (token) => {
//   return axios.create({
//     baseURL: apiUrl,
//     headers: {
//       ...axios.defaults.headers,
//       Authorization: `Bearer ${token?localStorage.getItem('accessToken'):token}`
//     }
//   })
// }

export {axiosHandle}
