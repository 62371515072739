import { logoutUser } from '../../../constants/common_constants'
import {
  deleteSingleEmployerData,
    modifySingleEmployerData,
  saveEmployers,
  startGettingEmployersData
} from '../../../constants/employers_constant'

const INITIAL_STATE = {
  isLoading: true,
  employers: []
}

const employersReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action

  switch (type) {
    case saveEmployers:
      return {
        ...state,
        isLoading: false,
        employers: payload
      }
    case startGettingEmployersData:
      return {
        ...state,
        isLoading: true
      }
    case logoutUser:
      return {
        ...state,
        employers: [],
        isLoading: true
      }

    case modifySingleEmployerData:
      let findObj = state.employers.find((e, i) => e._id === payload._id)
      let findInd = state.employers.indexOf(findObj)
      // let newCandidatesArray = state.candidates
      state.employers[findInd] = payload
      return {
        ...state,
        employers: [...state.employers],
        isLoading: false
      }

      case deleteSingleEmployerData:
      let newDataArray = state.employers.filter(
        (e, i) => e._id !== payload && e.isDeleted !== true
      )
      return {
        ...state,
        employers: newDataArray,
        isLoading: false
      }

    default:
      return state
  }
}

export default employersReducer
