const main = '#1D3557'
const colorInMainBackground = 'white'

export const allStyles = {
  addButton: {
    borderRadius: '7px',
    textTransform: 'none',
    py: '10px',
    px: '20px',
    fontWeight: 'normal',
    ':hover': {
      backgroundColor: '#254f8a'
    }
  },

  // Login Page
  loginPage: {
    width: '100%',
    height: '90vh'
  },

  loginBox: {
    height: 400,
  },
  loginText: {
    color: main
  },

  loginButton: {
    height: '3rem',
    width: '8rem',
    // textTransform:'none',
    // fontWeight:'bold',
    fontSize: 16
  },

  // Dashboard Sidebar
  dashboardSideBarHeading: {
    color: colorInMainBackground,
    mt:1,
    fontWeight: 'normal',
    fontSize:18
  },

  // Loading Page Asset
  pageLoading: {
    height: '90vh',
    width: '100%'
  },

  // Refresh Button
  refressButton: {
    py: '10px',
    px: '18px',
    color: 'lightGray',
    fontWeight: 'normal',
    borderRadius: '7px',
    textTransform: 'none',
    ':hover': {
      color: 'gray'
    }
  },
  logoutButton: {
    textTransform: 'none',
    fontSize: 16,
    px: 2
  },

  // Modal Fields Styles
  modalFields: {
    margin: '20px 0'
  },

  tableHeadCells: {
    height: 50
  },



  viewDetailsMainHeadings:{
    fontWeight:'bold',
    marginBottom:'6px'
  },

  actionButtons:{
    textTransform:'none',
    fontWeight:'normal',
    fontSize:15
  },







  // Material UI Theme
  muiTheme: {
    palette: {
      primary: {
        main: main
      },
      secondary: {
        main: '#a4a4a4'
      },
      // info:{
      //   main:'#c5a880'
      // }
    }
    // typography: {
    //   // fontFamily: 'Quicksand'
    //   fontFamily: 'Montserrat'
    // }
  }

  // loginPageAvatar:{
  //    width: 56,
  //    height: 56,
  //    bgcolor: '#1D3557'
  // }
}
