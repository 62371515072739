import { Skeleton, TableBody, TableCell, TableRow } from '@mui/material'
import React, { useState } from 'react'
import { useEffect } from 'react'

export const TableBodyDataSkeleton = ({numberOfColumns}) => {

  const [colsArray, setColsArray] = useState([])
  const [rowsArray, setRowsArray] = useState([]);



  useEffect(() => {
    const totalRows = 9
    const totalColumns = numberOfColumns

    let rowsArr = []
    let colArr = []

    for (let row = 0; row < totalRows; row++) {
      const element = `row ${row + 1}`
      rowsArr.push(element)
    }
    for (let col = 0; col < totalColumns; col++) {
      const element = `col ${col + 1}`
      colArr.push(element)
    }
    setColsArray(colArr)
    setRowsArray(rowsArr)
  }, [])

  return (
    <TableBody>
      {rowsArray.map((colElement, colInd) => (
        <TableRow key={colInd}>
          {colsArray.map((rowElement, rowInd) => (
            <TableCell sx={{py:3}} key={rowInd}>
              {' '}
              <Skeleton />{' '}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  )
}
