import { combineReducers } from 'redux'
import candidatesReducer from './dataReducers/candidate_reducers/candidatesReducers'
import singleCandidateReducer from './dataReducers/candidate_reducers/singleCandidateReducer'
import employersReducer from './dataReducers/employer_reducer/employersReducer'
import singleEmployerReducer from './dataReducers/employer_reducer/singleEmployerReducer'
import jobAdsReducer from './dataReducers/jobAdsReducers/jobAdsReducer'
import singleJobAdReducer from './dataReducers/jobAdsReducers/singleJobAdReducer'
import keySkillsReducer from './dataReducers/keySkillsReducer'
import qualificationsReducer from './dataReducers/qualificationsReducer'
import userStateReducer from './userDataReducers/userState'
import userTokensReducer from './userDataReducers/userTokens'

const reducers = combineReducers({
  userStateReducer,
  userTokensReducer,

  keySkillsReducer,
  qualificationsReducer,
  employersReducer,
  singleEmployerReducer,
  candidatesReducer,
  singleCandidateReducer,
  jobAdsReducer,
  singleJobAdReducer,
})

export default reducers
