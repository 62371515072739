import { apiHandle } from "./apiHandler"


// Get All Qualifications Api 
export const getQualificationsApi = async (token,dispatch,navigate) => {
    return await apiHandle(token,dispatch,navigate).get('/qualifications')
}

// Get Qualification By Id Api 
export const getQualificationByIdApi = async (id,token,dispatch,navigate) => {
    return await apiHandle(token,dispatch,navigate).get(`/qualification?id=${id}`)
}


// Create New Qualification Api 
export const createQualificationApi = async (data,token,dispatch,navigate) => {
    return await apiHandle(token,dispatch,navigate).post('/addQualification', data)
}


// Update Qualification Api 
export const updateQualificationApi = async (data,token,dispatch,navigate) => {
    return await apiHandle(token,dispatch,navigate).put(`/updateQualification?id=${data.id}`, data)
}


// Delete Qualification Api 
export const deleteQualificationApi = async (id,token,dispatch,navigate) => {

    return await apiHandle(token,dispatch,navigate).delete(`/deleteQualification?id=${id}`)
}
