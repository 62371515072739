import {
  deleteJobAdApi,
  getJobAdDataByIdApi,
  getJobAdsApi,
  suspendJobAdApi,
  tempSuspendJobAdApi
} from '../../api/jobAdsApi'
import {
  displayErrorToast,
  displaySuccessToast
} from '../../helper/toast_notification_function'
import {
  deleteSingleJobAdData,
  eraseSingleJobAdType,
  modifySingleJobAdData,
  saveJobAds,
  saveSingleJobAdData,
  startGettingJobAdsData,
  startGettingSingleJobAdData
} from '../constants/job_ads_constants'

export const getJobAds = (dispatch, token,navigate) => {
  dispatch({
    type: startGettingJobAdsData
  })
  getJobAdsApi(token,dispatch,navigate).then(e => {
    // displaySuccessToast(e.data.message[0])

    return dispatch({
      type: saveJobAds,
      payload: e.data.Ads ? e.data.Ads : []
    })
  })
}

export const getSingleJobAd = (dispatch, token, jobAdId,navigate) => {
  dispatch({
    type: startGettingSingleJobAdData
  })
  getJobAdDataByIdApi(token, jobAdId,dispatch,navigate).then(e => {
    return dispatch({
      type: saveSingleJobAdData,
      payload: e.data.Job
    })
  })
}

export const deleteSingleJobAd = (dispatch, token, jobAd,navigate) => {
  deleteJobAdApi(token, jobAd._id,dispatch,navigate)
    .then(e => {
      displaySuccessToast(`${jobAd.VacancyTitle} deleted successfully`)
      return dispatch({
        type: deleteSingleJobAdData,
        payload: jobAd._id
      })
    })
    .catch(error => {
      displayErrorToast(error.response.data.message[0])
    })
}

export const modifySingleJobAd = (
  dispatch,
  token,
  jobAdId,
  buttonCation,
  dataObject,
  navigate
) => {
  if (buttonCation === 'isSuspended') {
    let toSendData = { isSuspended: dataObject.isSuspended }
    suspendJobAdApi(token, jobAdId, toSendData,dispatch,navigate).then(e => {
      if (toSendData.isSuspended === true) {
        displaySuccessToast(`${dataObject.VacancyTitle} is suspended`)
      } else {
        displaySuccessToast(
          `${dataObject.VacancyTitle} is no longer suspended`
        )
      }
      return dispatch({
        type: modifySingleJobAdData,
        payload: dataObject
      })
    })
    .catch(error => {
      displayErrorToast(error.response.data.message[0])
    })
  } else if (buttonCation === 'isTemporarySuspended') {
    let toSendData = { isTemporarySuspended: dataObject.isTemporarySuspended }
    tempSuspendJobAdApi(token, jobAdId, toSendData,dispatch,navigate).then(e => {
      if (toSendData.isTemporarySuspended === true) {
        displaySuccessToast(
          `${dataObject.VacancyTitle} is temporary suspended`
        )
      } else {
        displaySuccessToast(
          `${dataObject.VacancyTitle} is no longer temporary suspended`
        )
      }
      return dispatch({
        type: modifySingleJobAdData,
        payload: dataObject
      })
    })
    .catch(error => {
      displayErrorToast(error.response.data.message[0])
    })
  }
}

export const eraseSingleJobAdData = dispatch => {
  dispatch({
    type: eraseSingleJobAdType
  })
}
