import { logoutUser} from "../../constants/common_constants"
import { saveKeySkills, startGettingKeySkillsData } from "../../constants/key_skills_constants"



const INITIAL_STATE = {
    isLoading:true,
    keySkills:[],
}


const keySkillsReducer = (state = INITIAL_STATE,action) => {

    const {type, payload} = action

    switch (type) {
        case saveKeySkills:
            return {
                ...state,
                isLoading:false,
                keySkills:payload
            }
        case startGettingKeySkillsData:
            return {
                ...state,
                isLoading:true,
            }
        case logoutUser:
            return {
                ...state,
                keySkills:[],
                isLoading:true,
            }
        default:
            return state
    }
}


export default keySkillsReducer