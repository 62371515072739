import {
  approveCandidateApi,
  deleteCandidateApi,
  getCandidateDataByIdApi,
  getCandidatesApi,
  suspendCandidateApi,
  tempSuspendCandidateApi
} from '../../api/candidatesApi'
import {
  deleteSingleCanditateData,
  eraseSingleCanditateData,
  modifySingleCanditateData,
  saveCandidates,
  saveSingleCanditateData,
  startGettingCandidatesData,
  startGettingSingleCandidateData
} from '../constants/candidates_constants'

import {
  displayErrorToast,
  displaySuccessToast
} from '../../helper/toast_notification_function'

export const getAllCandidates = (dispatch,token,navigate) => {
  dispatch({
    type: startGettingCandidatesData
  })
  getCandidatesApi(token,dispatch,navigate).then(e => {

    // displaySuccessToast(e.data.message[0])

    return dispatch({
      type: saveCandidates,
      payload: e.data.Candidates ? e.data.Candidates : []
    })
  })
}

export const getSingleCandidate = (dispatch, token, candidateId,navigate) => {
  dispatch({
    type: startGettingSingleCandidateData
  })
  getCandidateDataByIdApi(token, candidateId,dispatch,navigate).then(e => {
    return dispatch({
      type: saveSingleCanditateData,
      payload: e.data.Candidate[0]
    })
  })
}

export const deleteSingleCandidate = (dispatch, token, selectedRow,navigate) => {
  deleteCandidateApi(token, selectedRow._id,dispatch,navigate)
    .then(() => {
      displaySuccessToast(`User ${selectedRow.Username} deleted successfully`)
      return dispatch({
        type: deleteSingleCanditateData,
        payload: selectedRow._id
      })
    })
    .catch(error => {
      displayErrorToast(error.response.data.message[0])
    })
}

export const modifySingleCandidateData = (
  dispatch,
  token,
  candidateId,
  buttonCation,
  dataObject,
  navigate
) => {
  if (buttonCation === 'isApproved') {
    approveCandidateApi(token, candidateId,dispatch,navigate).then(e => {
      displaySuccessToast(`User ${dataObject.Username} application approved`)
      return dispatch({
        type: modifySingleCanditateData,
        payload: dataObject
      })
    })
  } else if (buttonCation === 'isSuspended') {
    let toSendData = { isSuspended: dataObject.isSuspended }
    suspendCandidateApi(token, candidateId, toSendData,dispatch,navigate).then(e => {
      if (toSendData.isSuspended === true) {
        displaySuccessToast(`User ${dataObject.Username} is suspended`)
      } else {
        displaySuccessToast(
          `User ${dataObject.Username} is no longer suspended`
        )
      }
      return dispatch({
        type: modifySingleCanditateData,
        payload: dataObject
      })
    })
  } else if (buttonCation === 'isTemporarySuspended') {
    let toSendData = { isTemporarySuspended: dataObject.isTemporarySuspended }
    tempSuspendCandidateApi(token, candidateId, toSendData,).then(e => {
      if (toSendData.isTemporarySuspended === true) {
        displaySuccessToast(
          `User ${dataObject.Username} is temporary suspended`
        )
      } else {
        displaySuccessToast(
          `User ${dataObject.Username} is no longer temporary suspended`
        )
      }
      return dispatch({
        type: modifySingleCanditateData,
        payload: dataObject
      })
    })
  }
}

export const eraseSingleCandidateData = dispatch => {
  dispatch({
    type: eraseSingleCanditateData
  })
}
