import { apiHandle } from "./apiHandler"


// Get All Job Ads Api 
export const getJobAdsApi = async (token,dispatch,navigate) => {
    return await apiHandle(token,dispatch,navigate).get('/jobAds')
}



// Get Job Ads Data By ID Api 
export const getJobAdDataByIdApi = async (token,id,dispatch,navigate) => {
    return await apiHandle(token,dispatch,navigate).get(`/jobAd?id=${id}`)
}


// Suspend Job Ads Api 
export const suspendJobAdApi = async (token,id,handleSuspendData,dispatch,navigate) => {
    return await apiHandle(token,dispatch,navigate).put(`/suspendJobAd?id=${id}`,handleSuspendData)
}

// Temporary Suspend Job Ads Api 
export const tempSuspendJobAdApi = async (token,id,handleTempSuspendData,dispatch,navigate) => {
    return await apiHandle(token,dispatch,navigate).put(`/temporarilySuspendJobAd?id=${id}`,handleTempSuspendData)
}

// Delete Job Ads Api 
export const deleteJobAdApi = async (token,id,dispatch,navigate) => {
    return await apiHandle(token,dispatch,navigate).put(`/deleteJobAd?id=${id}`)
}
