import { Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
// import { toBase64 } from '../helper/convert_buffer_to_file'

export const RenderBufferImage = ({ bufferArray }) => {
  const [image, setImage] = useState(null)
  const [errorOccurred, setErrorOccurred] = useState(false)

  useEffect(() => {

   try {
    const base64Img = btoa(String.fromCharCode(...new Uint8Array(bufferArray)))
    setImage(base64Img)
    
    
} catch (error) {
       setImage(null)
    setErrorOccurred(true)
   }

  }, [])

  return (
    <Stack 
    sx={{ maxWidth: 200,maxHeight:200,overflow:'hidden' }}
    >
      { errorOccurred ? 
        <Typography> An error occurred </Typography> : <img width='100%' src={`data:image/png;base64,${image}`} alt='helloworld' />
      }
    </Stack>
  )
}
