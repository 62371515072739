import { userAciveState, activeUserTokens,userTokensExpired  } from "../constants/user_state_constants"


const userState = (dispatch, user) => {
  return dispatch({
    type: userAciveState,
    user: user
  })
}

const saveTokens = (dispatch, payload) => {
  return dispatch({
    type: activeUserTokens,
    payload: payload
  })
}

const RemoveTokens = dispatch => {
  return dispatch({
    type: userTokensExpired
  })
}

export { userState, saveTokens,RemoveTokens}
