export const saveJobAds = 'SAVE_JOB_ADS'
export const startGettingJobAdsData = 'START_GETTING_JOB_ADS_DATA'

export const startGettingSingleJobAdData = 'START_GETTING_SINGLE_JOB_AD_DATA'
export const saveSingleJobAdData = 'SAVE_SINGLE_JOB_AD_DATA'
export const modifySingleJobAdData = 'MODIFY_SINGLE_JOB_AD_DATA'

export const deleteSingleJobAdData = 'DELETE_SINGLE_JOB_AD_DATA'


export const eraseSingleJobAdType = 'ERASE_SINGLE_JOB_AD_DATA'