import React, { useEffect, useState } from 'react'
import logo from '../assets/images/apex-logo.png'
import { Routes, Route, Link, useNavigate  } from 'react-router-dom'
import {
  Stack,
  Typography,
  SvgIcon,
  MenuItem,
  Divider,
  Paper,
  IconButton
} from '@mui/material'
import { FiMenu } from 'react-icons/fi'
import { IoIosArrowBack } from 'react-icons/io'
import { BsFillPersonBadgeFill} from 'react-icons/bs'
import { ImAddressBook } from 'react-icons/im'
// import { CgNotes } from 'react-icons/cg'
import { IoNewspaperSharp } from 'react-icons/io5'
import { FaGraduationCap } from 'react-icons/fa'
import { RiPencilRuler2Fill } from 'react-icons/ri'
import {
  RemoveTokens,
  userState
} from '../redux/actions/userActions'
import { useDispatch, useSelector } from 'react-redux'
import { LogoutButton } from '../assets/buttons/LogoutButton'
import { Candidates, Employers, JobAds, KeySkills, Qualifications } from './index'
import {getAllKeySkills} from '../redux/actions/keySkillsActions'
import { getQualifications } from '../redux/actions/qualificationsActions'
import { getAllEmployers } from '../redux/actions/employersActions'
import { getAllCandidates } from '../redux/actions/candidatesActions'
import { getJobAds } from '../redux/actions/jobAdsActions'




let textColor = '#1D3557'

export const Dashboard = () => {
  const [sideBarActive, setSideBarActive] = useState(true)

  // const state = useSelector(state => state)

  const userStatus = useSelector(state => state.userStateReducer.user)
  // const userStatus = useSelector(state => state.userStateReducer.user)
  const accessToken = useSelector(state => state.userTokensReducer.tokens.accessToken)



  const navigate = useNavigate()

  const dispatch = useDispatch()

  let logoutHandle = () => {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    userState(dispatch, false)
    RemoveTokens(dispatch)
    navigate('/')
  }


  useEffect(() => {
    getAllKeySkills(dispatch,accessToken,navigate)
    getQualifications(dispatch,accessToken,navigate)
    getAllEmployers(dispatch,accessToken,navigate)
    getAllCandidates(dispatch,accessToken,navigate)
    getJobAds(dispatch,accessToken,navigate)
  }, []);
  

  
  
useEffect(() => {
    if (!userStatus) {
      RemoveTokens(dispatch)
    }
  }, [userStatus])

  let listOfMenuItems = [
    {
      caption: 'Key Skills',
      linkTo: '',
      icon: <RiPencilRuler2Fill />,
      element: <KeySkills />
    },
    {
      caption: 'Qualifications',
      linkTo: 'qualifications',
      icon: <FaGraduationCap />,
      element: <Qualifications />
    },
    {
      caption: 'Job Ads',
      linkTo: 'job-ads',
      icon: <IoNewspaperSharp />,
      element: <JobAds />
    },
    {
      caption: 'Employers',
      linkTo: 'employers',
      icon: <BsFillPersonBadgeFill />,
      element: <Employers />
    },
    {
      caption: 'Candidates',
      linkTo: 'candidates',
      icon: <ImAddressBook />,
      element: <Candidates />
    },
  ]

  return (
    <>
      <Stack>
        <Stack className={`sideBar ${sideBarActive ? 'active' : ''}`}>
          <Stack>
            <Stack sx={{px:1,py:3}}>
              <img width='250px' src={logo} />
            </Stack>

            <Divider
              sx={{ backgroundColor: 'gray',mt:2,mx: 3 }}
              orientation='horizontal'
              variant='fullWidth'
              light
            />

            <Stack sx={{ px: 3, py: 3, mt: 3 }} direction='column' spacing={4}>
              {listOfMenuItems.map((e, i) => (
                <Link key={i} color='white' to={e.linkTo}>
                  <MenuItem
                    disableGutters
                    dense
                    key={i}
                    autoFocus={false}
                    style={{
                      color: 'white',
                      textDecoration: 'none'
                    }}
                  >
                    <Stack direction='row' alignItems='center'>
                      <SvgIcon
                        sx={{
                          overflow: 'initial',
                          color: 'white',
                          fontSize: 23,
                          marginRight: '25px'
                        }}
                      >
                        {e.icon}
                      </SvgIcon>

                      <Stack>
                        <Typography sx={{ fontSize: 20, fontWeight: 'light' }}>
                          {e.caption}
                        </Typography>
                      </Stack>
                    </Stack>
                  </MenuItem>
                </Link>
              ))}
            </Stack>
          </Stack>
        </Stack>

        <Stack className='pages'>
          <Paper
            elevation={2}
            sx={{ px: 3, py: 3, mb: 4, borderRadius: 0 }}
            children={
              <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
              >
                {sideBarActive ? (
                  <IconButton
                    color='primary'
                    onClick={() => setSideBarActive(!sideBarActive)}
                  >
                    {' '}
                    <IoIosArrowBack color={textColor} size={25} />
                  </IconButton>
                ) : (
                  <IconButton
                    color='primary'
                    onClick={() => setSideBarActive(!sideBarActive)}
                  >
                    {' '}
                    <FiMenu color={textColor} size={25} />
                  </IconButton>
                )}

                <Stack>
                  <LogoutButton onClickHandle={logoutHandle} />
                </Stack>
              </Stack>
            }
          />
          <Stack sx={{ px: 3 }}>
            <Routes>
              {listOfMenuItems.map((e, i) => (
                <Route key={i} path={e.linkTo} element={e.element} />
              ))}
            </Routes>
          </Stack>
        </Stack>
      </Stack>
    </>
  )
}
