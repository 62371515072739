export const actionButtonCaptions = {
    viewDetailsButton:'Details',
    approveButton:'Approve',
    deleteButton:'Delete',
    suspendButton:'Suspend',
    tempSuspendButton:'Temp.Suspend',


    activateSuspend:'Activate',
    activateTempSuspend:'Activate',

}