import { apiHandle } from "./apiHandler"


// Get All Key Skills Api 
export const getKeySkillsApi = async (token,dispatch,navigate) => {
    return await apiHandle(token,dispatch,navigate).get('/keySkills')
}

// Get All Key Skill By Id Api 
export const getKeySkillByIdApi = async (id,token,dispatch,navigate) => {
    return await apiHandle(token,dispatch,navigate).get(`keySkill?id=${id}`)
}


// Create New Key Skill Api 
export const createKeySkillApi = async (data,token,dispatch,navigate) => {
    return await apiHandle(token,dispatch,navigate).post('/addKeySkill', data)
}


// Update Key Skill Api 
export const updateKeySkillApi = async (data,token,dispatch,navigate) => {
    return await apiHandle(token,dispatch,navigate).put(`/updateKeySkill?id=${data.id}`, data)
}


// Delete Key Skill Api 
export const deleteKeySkillApi = async (id,token,dispatch,navigate) => {

    return await apiHandle(token,dispatch,navigate).delete(`/deleteKeySkill?id=${id}`)
}


