import { apiHandle } from "./apiHandler"


// Get All Candidates Api 
export const getCandidatesApi = async (token,dispatch,navigate) => {
    return await apiHandle(token,dispatch,navigate).get('/candidates')
}

// Get Candidate Data By ID Api 
export const getCandidateDataByIdApi = async (token,id,dispatch,navigate) => {
    return await apiHandle(token,dispatch,navigate).get(`/candidate?id=${id}`)
}

// Get Candidate Data By ID Api 
export const getCandidateResumeByIdApi = async (token,id,dispatch,navigate) => {
    return await apiHandle(token,dispatch,navigate).get(`/getResumeByCandidateId?id=${id}`)
}

// Approve Candidate Api 
export const approveCandidateApi = async (token,id,dispatch,navigate) => {
    return await apiHandle(token,dispatch,navigate).put(`/approveCandidate?id=${id}`)
}

// Suspend Candidate Api 
export const suspendCandidateApi = async (token,id,handleSuspendData,dispatch,navigate) => {
    return await apiHandle(token,dispatch,navigate).put(`/suspendCandidate?id=${id}`,handleSuspendData)
}

// Temporary Suspend Candidate Api 
export const tempSuspendCandidateApi = async (token,id,handleTempSuspendData,dispatch,navigate) => {
    return await apiHandle(token,dispatch,navigate).put(`/temporarilySuspendCandidate?id=${id}`,handleTempSuspendData)
}

// Temporary Suspend Candidate Api 
export const deleteCandidateApi = async (token,id,dispatch,navigate) => {
    return await apiHandle(token,dispatch,navigate).put(`/deleteCandidate?id=${id}`)
}
