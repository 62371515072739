import { approveEmployerApi, deleteEmployerApi, getEmployerByIdApi, getEmployersApi, suspendEmployerApi, tempSuspendEmployerApi } from "../../api/employersApi"
import { displayErrorToast, displaySuccessToast } from "../../helper/toast_notification_function"
import { eraseSingleEmployerDataType, modifySingleEmployerData, saveEmployers, startGettingEmployersData, startGettingSingleEmployerData,saveSingleEmployerData, deleteSingleEmployerData } from "../constants/employers_constant"



export const  getAllEmployers = (dispatch,token,navigate) =>{
    dispatch({
       type:startGettingEmployersData,        
   })
   getEmployersApi(token,dispatch,navigate).then((e)=>{
    // displaySuccessToast(e.data.message[0])
     return dispatch({
         type:saveEmployers,
         payload:e.data.Employers ? e.data.Employers : []
         
     })
 })
}



export const getSingleEmployer = (dispatch, token, employerId,navigate) => {
    dispatch({
      type: startGettingSingleEmployerData
    })
    getEmployerByIdApi(token, employerId,dispatch,navigate).then(e => {
      return dispatch({
        type: saveSingleEmployerData,
        payload: e.data.Employer
      })
    })
  }


  export const deleteSingleEmployer = (dispatch, token, selectedRow,navigate) => {
    deleteEmployerApi(token, selectedRow._id,dispatch,navigate)
      .then(() => {
        displaySuccessToast(`User ${selectedRow.Username} deleted successfully`)
        return dispatch({
          type: deleteSingleEmployerData,
          payload: selectedRow._id
        })
      })
      .catch(error => {
        displayErrorToast(error.response.data.message[0])
      })
  }
  






export const modifySingleEmployer = (
    dispatch,
    token,
    employerId,
    buttonCation,
    dataObject,
    navigate
  ) => {
    if (buttonCation === 'isApproved') {
      approveEmployerApi(token, employerId,dispatch,navigate).then(e => {
        displaySuccessToast(`User ${dataObject.Username} application approved`)
        return dispatch({
          type: modifySingleEmployerData,
          payload: dataObject
        })
      })
    } else if (buttonCation === 'isSuspended') {
        let toSendData = {isSuspended:dataObject.isSuspended}
        suspendEmployerApi(token, employerId, toSendData,dispatch,navigate).then(e => {
          if(toSendData.isSuspended === true){
            displaySuccessToast(`User ${dataObject.Username} is suspended`)
          }
          else{
            displaySuccessToast(`User ${dataObject.Username} is no longer suspended`)
          }
        return dispatch({
          type: modifySingleEmployerData,
          payload: dataObject
        })
      })
    } else if (buttonCation === 'isTemporarySuspended') {
      let toSendData = {isTemporarySuspended:dataObject.isTemporarySuspended}
      tempSuspendEmployerApi(token, employerId, toSendData,dispatch,navigate).then(e => {
        if(toSendData.isTemporarySuspended === true){
          displaySuccessToast(`User ${dataObject.Username} is temporary suspended`)
        }
        else{
          displaySuccessToast(`User ${dataObject.Username} is no longer temporary suspended`)
        }
        return dispatch({
          type: modifySingleEmployerData,
          payload: dataObject
        })
      })
    }
  }
  
  export const eraseSingleEmployerData = dispatch => {
    dispatch({
      type: eraseSingleEmployerDataType
    })
  }


