import * as React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { ToastContainer } from 'react-toastify'
import { ViewDetailsMainHeadings } from '../../components/ViewDetailsMainHeadings'
import { Divider, Grid, IconButton, Stack, Typography } from '@mui/material'
import { allStyles } from '../../allStyles'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useState } from 'react'
import { LoadingForData } from '../../assets/LoadingForData'
import { formatDate } from '../../helper/date_format_function'
import { MdOutlineClose } from 'react-icons/md'
import { currencyFormat } from '../../helper/currency_format_function'
import {RenderBufferImage} from '../../components/RenderBufferImage'

import {
  deleteSingleJobAd,
  modifySingleJobAd
} from '../../redux/actions/jobAdsActions'
import { actionButtonCaptions } from '../../helper/action_button_captions'
import { useNavigate } from 'react-router-dom'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}))

let jobDetails = [
  {
    caption: 'Title:',
    id: 'VacancyTitle',
    col: 6
  },
  {
    caption: 'Category:',
    id: 'VacancyCategory',
    col: 6
  },
  {
    caption: 'Type:',
    id: 'VacancyType',
    col: 6
  },
  {
    caption: 'Location',
    id: 'Location',
    col: 6
  },
  {
    caption: 'Closing Date:',
    id: 'ClosingDate',
    col: 6
  },
  {
    caption: 'Description:',
    id: 'JobDescription',
    col: 12
  },
  {
    caption: 'Created At:',
    id: 'createdAt',
    col: 6
  }
]

let salaryRange = [
  {
    caption: 'From:',
    id: 'From',
    col: 3
  },
  {
    caption: 'To:',
    id: 'To',
    col: 3
  },
  {
    caption: 'Currency:',
    id: 'Currency',
    col: 3
  }
]

let applicationStatus = [
  {
    caption: 'Updated At:',
    id: 'updatedAt'
  },
  // {
  //   caption: 'Set Default Company Details:',
  //   id: 'SetDefaultCompanyDetails'
  // },
  {
    caption: 'Deleted:',
    id: 'isDeleted'
  },
  {
    caption: 'Suspended:',
    id: 'isSuspended'
  },
  {
    caption: 'Temporary Suspended:',
    id: 'isTemporarySuspended'
  }
]

const BootstrapDialogTitle = props => {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <MdOutlineClose />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired
}

export const ViewJobAdDetails = ({
  open,
  closeClickHandle
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [dataToView, setDataToView] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const accessToken = useSelector(state => state.userTokensReducer.tokens.accessToken)
  const selectedRowData = useSelector(state => state.singleJobAdReducer)

  const closeHandle = () => {
    closeClickHandle()
  }


  useEffect(() => {
    setDataToView(selectedRowData.singleJobAd)
    setIsLoading(selectedRowData.isLoading)
  }, [selectedRowData])

  const actionClickHandle = (buttonCaption, toSetStatus) => {
    if (buttonCaption === 'isDeleted') {
      deleteSingleJobAd(dispatch, accessToken, dataToView,navigate)
      closeClickHandle()
    } else {
      dataToView[buttonCaption] = toSetStatus
      modifySingleJobAd(
        dispatch,
        accessToken,
        dataToView._id,
        buttonCaption,
        dataToView,
        navigate
      )
    }
  }

  return (
    <div>
      <BootstrapDialog
        maxWidth='xl'
        aria-labelledby='customized-dialog-title'
        open={open}
      >
        <BootstrapDialogTitle
          id='customized-dialog-title'
          onClose={closeHandle}
        >
          Job Ad Details
        </BootstrapDialogTitle>
        <DialogContent dividers sx={{ maxHeight: '720px', minWidth: 1100 }}>
          {isLoading ? (
            <LoadingForData />
          ) : (
            <Stack spacing={3}>
              

              <Stack>
              <Grid container>
                  <Grid item md={12} lg={12} xl={12} sx={{ mb: 3 }}>
                    <Stack spacing={1}>
                      {dataToView.Logo.type === 'Buffer' && (
                        <RenderBufferImage bufferArray={dataToView.Logo.data}  />
                      )}
                    </Stack>
                  </Grid>
                </Grid>
              </Stack>


              <Stack spacing={1}>
                  <ViewDetailsMainHeadings headingCaption='Job Details' />

                  <Grid container>
                    {jobDetails.map((e, i) => (
                      <Grid
                        key={i}
                        item
                        md={e.col}
                        lg={e.col}
                        xl={e.col}
                        sx={{ mb: 3 }}
                      >
                        {e.id === 'ClosingDate' || e.id === 'createdAt' ? (
                          <Stack direction='row' spacing={2}>
                            <Typography sx={{ fontWeight: 'bold' }}>
                              {e.caption}
                            </Typography>
                            <Typography>
                              {formatDate(dataToView[e.id])}
                            </Typography>
                          </Stack>
                        ) : (
                          <Stack direction='row' spacing={2}>
                            <Typography sx={{ fontWeight: 'bold' }}>
                              {e.caption}
                            </Typography>
                            <Typography>{dataToView[e.id]}</Typography>
                          </Stack>
                        )}
                      </Grid>
                    ))}
                  </Grid>
              </Stack>

              <Divider />

              <Stack spacing={1}>
                <ViewDetailsMainHeadings headingCaption='Salary Range' />
                <Grid container>
                  {salaryRange.map((e, i) => (
                    <Grid
                      key={i}
                      item
                      md={e.col}
                      lg={e.col}
                      xl={e.col}
                      sx={{ mb: 3 }}
                    >
                      {e.id !== 'Currency' ? (
                        <Stack direction='row' spacing={2}>
                          <Typography sx={{ fontWeight: 'bold' }}>
                            {e.caption}
                          </Typography>
                          <Typography>
                            {currencyFormat(dataToView.SalaryRange[e.id])}
                          </Typography>
                        </Stack>
                      ) : (
                        <Stack direction='row' spacing={2}>
                          <Typography sx={{ fontWeight: 'bold' }}>
                            {e.caption}
                          </Typography>
                          <Typography>
                            {dataToView.SalaryRange[e.id]}
                          </Typography>
                        </Stack>
                      )}
                    </Grid>
                  ))}
                </Grid>
              </Stack>

              <Divider />

              <Stack spacing={2}>
                <ViewDetailsMainHeadings headingCaption='Application Status' />

                <Grid container>
                  {applicationStatus.map((e, i) => (
                    <Grid key={i} item md={4} lg={4} xl={4} sx={{ mb: 3 }}>
                      <Stack direction='row' spacing={2}>
                        <Typography sx={{ fontWeight: 'bold' }}>
                          {e.caption}
                        </Typography>
                        <Typography>
                          {dataToView[e.id] === false
                            ? 'No'
                            : dataToView[e.id] === true
                            ? 'Yes'
                            : formatDate(dataToView[e.id])}
                        </Typography>
                      </Stack>
                    </Grid>
                  ))}
                </Grid>
              </Stack>
            </Stack>
          )}
        </DialogContent>
        <DialogActions>
          {dataToView && (
            <Stack direction='row' spacing={3} sx={{ mx: 2, my: 1 }}>
              <Button
                sx={allStyles.actionButtons}
                variant='contained'
                color='error'
                onClick={() => actionClickHandle('isDeleted', true)}
              >
                {actionButtonCaptions.deleteButton}
              </Button>
              {dataToView.isSuspended ? (
                <Button
                  sx={allStyles.actionButtons}
                  variant='contained'
                  color='info'
                  onClick={() => actionClickHandle('isSuspended', false)}
                >
                  {actionButtonCaptions.activateSuspend}
                </Button>
              ) : (
                <Stack direction='row' spacing={3}>
                  <Button
                    sx={allStyles.actionButtons}
                    variant='contained'
                    color='info'
                    onClick={() => actionClickHandle('isSuspended', true)}
                  >
                    {actionButtonCaptions.suspendButton}
                  </Button>
                  {dataToView.isTemporarySuspended ? (
                    <Button
                      sx={allStyles.actionButtons}
                      variant='outlined'
                      color='warning'
                      onClick={() =>
                        actionClickHandle('isTemporarySuspended', false)
                      }
                    >
                      {actionButtonCaptions.activateTempSuspend}
                    </Button>
                  ) : (
                    <Button
                      sx={allStyles.actionButtons}
                      variant='contained'
                      color='warning'
                      onClick={() =>
                        actionClickHandle('isTemporarySuspended', true)
                      }
                    >
                      {actionButtonCaptions.tempSuspendButton}
                    </Button>
                  )}
                </Stack>
              )}
            </Stack>
          )}
        </DialogActions>
      </BootstrapDialog>
      <ToastContainer />
    </div>
  )
}
