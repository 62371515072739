import * as React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { ToastContainer } from 'react-toastify'
import { ViewDetailsMainHeadings } from '../../components/ViewDetailsMainHeadings'
import {RenderBufferImage} from '../../components/RenderBufferImage'
import {Buffer} from 'buffer'

import {
  Divider,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { allStyles } from '../../allStyles'
import { useDispatch, useSelector } from 'react-redux'

import { useEffect } from 'react'
import { useState } from 'react'
import {
  getCandidateResumeByIdApi
} from '../../api/candidatesApi'
import { LoadingForData } from '../../assets/LoadingForData'
import { formatDate } from '../../helper/date_format_function'
import { MdOutlineClose } from 'react-icons/md'
import { FiDownload } from 'react-icons/fi'
import {
  deleteSingleCandidate,
  modifySingleCandidateData
} from '../../redux/actions/candidatesActions'
import { RenderQualification } from '../../components/RenderQualification'
import { RenderSkills } from '../../components/RenderSkills'
import { useNavigate } from 'react-router-dom'


const noDataMessage = (dataCaption) => {
  return `----- No ${dataCaption} data set yet! -----`
} 

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}))

let basicInfoData = [
  {
    caption: 'First Name:',
    id: 'FirstName'
  },
  {
    caption: 'Last Name:',
    id: 'LastName'
  },
  {
    caption: 'User Name:',
    id: 'Username'
  },
  {
    caption: 'Phone Number:',
    id: 'PhoneNumber'
  },
  {
    caption: 'Email:',
    id: 'Email'
  }
]

let generalInfo = [
  {
    caption: 'CNIC Number:',
    id: 'CNIC'
  },
  {
    caption: 'City:',
    id: 'City'
  },
  {
    caption: 'Gender:',
    id: 'Gender'
  },
  {
    caption: 'Profession:',
    id: 'Profession'
  },
  {
    caption: 'Religion:',
    id: 'Religion'
  },
  {
    caption: 'Relocation:',
    id: 'Relocation'
  },
  {
    caption: 'Communication Address:',
    id: 'communicationAddress'
  }
]

let applicationStatus = [
  {
    caption: 'Approved:',
    id: 'isApproved'
  },
  {
    caption: 'Deleted:',
    id: 'isDeleted'
  },
  {
    caption: 'Suspended:',
    id: 'isSuspended'
  },
  {
    caption: 'Temporary Suspended:',
    id: 'isTemporarySuspended'
  },
  {
    caption: 'Resume File Exist:',
    id: 'resumeFileExist'
  }
]

const BootstrapDialogTitle = props => {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <MdOutlineClose />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired
}

export const ViewCandidateDetails = ({
  open,
  closeClickHandle
  //   selectedData
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [dataToView, setDataToView] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const accessToken = useSelector(state => state.userTokensReducer.tokens.accessToken)
  const selectedRowData = useSelector(state => state.singleCandidateReducer)

  const closeHandle = () => {
    closeClickHandle()
  }

  useEffect(() => {
    setDataToView(selectedRowData.singleCandidate)
    setIsLoading(selectedRowData.isLoading)

  }, [selectedRowData])

  const actionClickHandle = (buttonCaption, toSetStatus) => {
    if (buttonCaption === 'isDeleted') {
      deleteSingleCandidate(dispatch, accessToken, dataToView._id,navigate)
      closeClickHandle()
    } else {
      dataToView[buttonCaption] = toSetStatus
      modifySingleCandidateData(
        dispatch,
        accessToken,
        dataToView._id,
        buttonCaption,
        dataToView,
        navigate
      )
    }
  }

  const openInNewTab = (url) => {
    
    let win = window.open();
    win.document.write('<iframe src="' + url  + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>')
  }
  
  //Then add to your onClick


  const downloadResumeHandle = async () => {
    // const Buffer = require('buffer').Buffer

    getCandidateResumeByIdApi(accessToken, dataToView._id,dispatch,navigate).then(res => {

      const buffer = res.data?.Resume?.data
      const base64 = Buffer.from(buffer).toString('base64')
      const resumeLink = 'data:application/pdf;base64,' + base64
      openInNewTab(resumeLink)
    })
  }

  return (
    <div>
      <BootstrapDialog
        maxWidth='xl'
        aria-labelledby='customized-dialog-title'
        open={open}
      >
        <BootstrapDialogTitle
          id='customized-dialog-title'
          onClose={closeHandle}
        >
          Candidate Details
        </BootstrapDialogTitle>
        <DialogContent dividers sx={{ maxHeight: '720px', minWidth: 1100 }}>
          {isLoading ? (
            <LoadingForData />
          ) : (
            <Stack spacing={3}>
              <Stack>
                <Grid container>
                  <Grid item md={12} lg={12} xl={12}>
                    <Stack spacing={4} alignItems='center'>
                      {dataToView?.Resume?.[0] && dataToView?.Resume?.[0]?.ImageURL && dataToView?.Resume?.[0]?.ImageURL?.data ? (
                        <RenderBufferImage bufferArray={dataToView.Resume[0].ImageURL.data}  />
                      ) : null}

                      {dataToView && dataToView.resumeFileExist && (
                        <Stack direction='row'>
                          <Button
                            variant='outlined'
                            onClick={downloadResumeHandle}
                            sx={allStyles.actionButtons}
                            startIcon={<FiDownload />}
                          >
                            Download Resume
                          </Button>
                        </Stack>
                      )}
                    </Stack>
                  </Grid>
                </Grid>
              </Stack>

              <Stack spacing={1}>
                <ViewDetailsMainHeadings headingCaption='Basic Information' />

                <Grid container>
                  {basicInfoData.map((e, i) => (
                    <Grid key={i} item md={6} lg={6} xl={6} sx={{ mb: 3 }}>
                      <Stack direction='row' spacing={2}>
                        <Typography sx={{ fontWeight: 'bold' }}>
                          {e.caption}
                        </Typography>
                        <Typography>{dataToView[e.id] ? dataToView[e.id] : 'N/A'}</Typography>
                      </Stack>
                    </Grid>
                  ))}
                </Grid>
              </Stack>

              <Divider />

              <Stack spacing={1}>
                <Stack>
                  <Typography align='center' variant='h5'>
                    Resume Section
                  </Typography>
                  <ViewDetailsMainHeadings headingCaption='Experience' />
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align='left' sx={{ width: 50 }}>
                            Employer
                          </TableCell>
                          <TableCell align='left' sx={{ width: 100 }}>
                            Job Title
                          </TableCell>
                          <TableCell align='left' sx={{ width: 100 }}>
                            Start Date
                          </TableCell>
                          <TableCell align='left' sx={{ width: 100 }}>
                            End Date
                          </TableCell>
                          <TableCell align='left' sx={{ width: 250 }}>
                            Notes
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dataToView && dataToView?.Resume?.[0]?.Experience ? (
                          <TableRow>
                            <TableCell
                              sx={{ py: 4 }}
                              align='center'
                              colSpan={5}
                            >
                              {noDataMessage('Experience')}
                            </TableCell>
                          </TableRow>
                        ) : dataToView && dataToView?.Resume?.[0]?.Experience?.length < 1 ? (
                          <TableRow>
                            <TableCell
                              sx={{ py: 4 }}
                              align='center'
                              colSpan={5}
                            >
                              {noDataMessage('Experience')}
                            </TableCell>
                          </TableRow>
                        ) : (
                          dataToView && dataToView?.Resume?.[0]?.Experience?.map((e, i) => (
                            <TableRow key={i}>
                              <TableCell align='left'>{e.Employer ? e.Employer : '--'}</TableCell>
                              <TableCell align='left'>{e.JobTitle ? e.JobTitle : '--'}</TableCell>
                              <TableCell align='left'>
                                {e.StartDate ? formatDate(e.StartDate) : '--'}
                              </TableCell>
                              <TableCell align='left'>
                                {e.EndDate ? formatDate(e.EndDate) : "--"}
                              </TableCell>
                              <TableCell align='left'>{e.Notes ? e.Notes : '--'}</TableCell>
                            </TableRow>
                          ))
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Stack>

                <Stack sx={{ pt: 3 }}>
                  <ViewDetailsMainHeadings headingCaption='Education' />
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align='left' sx={{ width: 50 }}>
                            SchoolName
                          </TableCell>
                          <TableCell align='left' sx={{ width: 100 }}>
                            Qualification
                          </TableCell>
                          <TableCell align='left' sx={{ width: 100 }}>
                            Start Date
                          </TableCell>
                          <TableCell align='left' sx={{ width: 100 }}>
                            End Date
                          </TableCell>
                          <TableCell align='left' sx={{ width: 250 }}>
                            Notes
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dataToView && dataToView?.Resume?.[0]?.Education ? <TableRow>
                            <TableCell
                              sx={{ py: 4 }}
                              align='center'
                              colSpan={5}
                            >
                              {noDataMessage('Education')}
                            </TableCell>
                          </TableRow> : dataToView && dataToView?.Resume?.[0].Education.length < 1  ? (
                          <TableRow>
                            <TableCell
                              sx={{ py: 4 }}
                              align='center'
                              colSpan={5}
                            >{noDataMessage('Education')}
                            </TableCell>
                          </TableRow>
                        ) : (
                          dataToView && dataToView?.Resume?.[0]?.Education?.map((e, i) => (
                            <TableRow key={i}>
                              <TableCell align='left'>{e.SchoolName ? e.SchoolName : '--'}</TableCell>
                              <TableCell align='left'>
                                <RenderQualification
                                  // qualificationId={'61c7802e2b838e09ba0938e4'}
                                  qualificationId={e.Qualification ? e.Qualification : '--'}
                                />
                              </TableCell>
                              <TableCell align='left'>
                                {e.StartDate ? formatDate(e.StartDate) : '--'}
                              </TableCell>
                              <TableCell align='left'>
                                {e.EndDate ? formatDate(e.EndDate) : '--'}
                              </TableCell>
                              <TableCell align='left'>{e.Notes ? e.Notes : '--'}</TableCell>
                            </TableRow>
                          ))
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Stack>
              </Stack>

              <Stack spacing={2}>
                <ViewDetailsMainHeadings headingCaption='General Information' />

                <Grid container>
                  {generalInfo.map((e, i) =>
                    e.id === 'communicationAddress' ? (
                      <Grid key={i} item md={12} lg={12} xl={12} sx={{ mb: 3 }}>
                        <Stack direction='row' spacing={2}>
                          <Typography sx={{ fontWeight: 'bold' }}>
                            {e.caption === '' ? 'Not Set' : e.caption}
                          </Typography>
                          <Typography>
                            {dataToView?.Resume?.[0]?.GeneralInformation?.[e.id] ? dataToView?.Resume?.[0]?.GeneralInformation?.[e.id] :'N/A'}
                          </Typography>
                        </Stack>
                      </Grid>
                    ) : (
                      <Grid key={i} item md={4} lg={4} xl={4} sx={{ mb: 3 }}>
                        <Stack direction='row' spacing={2}>
                          <Typography sx={{ fontWeight: 'bold' }}>
                            {e.caption === '' ? 'Not Set' : e.caption}
                          </Typography>
                          <Typography>
                            {dataToView?.Resume?.[0]?.GeneralInformation?.[e.id] ? dataToView?.Resume?.[0]?.GeneralInformation?.[e.id] :'N/A'}
                          </Typography>
                        </Stack>
                      </Grid>
                    )
                  )}
                </Grid>
              </Stack>

              <Divider />

              <Stack spacing={2}>
                <ViewDetailsMainHeadings headingCaption='Skills' />

                {!dataToView?.Resume?.[0]?.GeneralInformation?.Skills ? (
                  <Typography align='center' style={{paddingBottom:5}}>{noDataMessage('Skills')}</Typography>
                ) : dataToView?.Resume?.[0].GeneralInformation?.Skills?.length < 1 ? (
                  <Typography align='center' style={{paddingBottom:5}}>{noDataMessage('Skills')}</Typography>
                ) : (
                  <Grid container>
                    {dataToView?.Resume?.[0]?.GeneralInformation?.Skills?.map(
                      (e, i) => (
                        <Grid key={i} item md={4} lg={4} xl={4} sx={{ mb: 3 }}>
                          <RenderSkills skillId={e.skill} />
                        </Grid>
                      )
                    )}
                  </Grid>
                )}
              </Stack>

              <Divider />

              <Stack spacing={2}>
                <ViewDetailsMainHeadings headingCaption='Application Status' />

                <Grid container>
                  {applicationStatus.map((e, i) => (
                    <Grid key={i} item md={4} lg={4} xl={4} sx={{ mb: 3 }}>
                      <Stack direction='row' spacing={2}>
                        <Typography sx={{ fontWeight: 'bold' }}>
                          {e.caption}
                        </Typography>
                        <Typography>
                          {dataToView[e.id] === false ? 'No' : 'Yes'}
                        </Typography>
                      </Stack>
                    </Grid>
                  ))}
                </Grid>
              </Stack>
            </Stack>
          )}
        </DialogContent>
        <DialogActions>
          {dataToView && (
            <Stack direction='row' spacing={3} sx={{ mx: 2, my: 1 }}>
              <Button
                sx={allStyles.actionButtons}
                variant='contained'
                color='error'
                onClick={() => actionClickHandle('isDeleted', true)}
              >
                Delete
              </Button>
              {dataToView.isApproved ? (
                <Stack direction='row' spacing={3} sx={{ mx: 2, my: 1 }}>
                  {dataToView.isSuspended ? (
                    <Button
                      sx={allStyles.actionButtons}
                      variant='contained'
                      color='info'
                      onClick={() => actionClickHandle('isSuspended', false)}
                    >
                      Activate Suspended
                    </Button>
                  ) : (
                    <Stack direction='row' spacing={3}>
                      <Button
                        sx={allStyles.actionButtons}
                        variant='contained'
                        color='info'
                        onClick={() => actionClickHandle('isSuspended', true)}
                      >
                        Suspend
                      </Button>
                      {dataToView.isTemporarySuspended ? (
                        <Button
                          sx={allStyles.actionButtons}
                          variant='contained'
                          color='warning'
                          onClick={() =>
                            actionClickHandle('isTemporarySuspended', false)
                          }
                        >
                          Activate Temporary Suspended
                        </Button>
                      ) : (
                        <Button
                          sx={allStyles.actionButtons}
                          variant='contained'
                          color='warning'
                          onClick={() =>
                            actionClickHandle('isTemporarySuspended', true)
                          }
                        >
                          Temporary Suspend
                        </Button>
                      )}
                    </Stack>
                  )}
                </Stack>
              ) : (
                <Stack direction='row' spacing={3} sx={{ mx: 2, my: 1 }}>
                  <Button
                    sx={allStyles.actionButtons}
                    variant='contained'
                    color='success'
                    onClick={() => actionClickHandle('isApproved', true)}
                  >
                    Approve
                  </Button>
                  <Button
                    sx={allStyles.actionButtons}
                    variant='contained'
                    color='error'
                    onClick={() => actionClickHandle('isDeleted', true)}
                  >
                    Delete
                  </Button>
                </Stack>
              )}
            </Stack>
          )}
        </DialogActions>
      </BootstrapDialog>
      <ToastContainer />
    </div>
  )
}
