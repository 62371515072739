export const saveEmployers = 'SAVE_EMPLOYERS'
export const startGettingEmployersData = 'START_GETTING_EMPLOYERS_DATA'


export const startGettingSingleEmployerData = 'START_GETTING_SINGLE_EMPLOYER_DATA'
export const saveSingleEmployerData = 'SAVE_SINGLE_EMPLOYER_DATA'
export const modifySingleEmployerData = 'MODIFY_SINGLE_EMPLOYER_DATA'


export const deleteSingleEmployerData = 'DELETE_SINGLE_EMPLOYER_DATA'
export const eraseSingleEmployerDataType = 'ERASE_SINGLE_EMPLOYER_DATA'

