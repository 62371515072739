import { getKeySkillByIdApi, getKeySkillsApi } from "../../api/keySkillsApi"
import { saveKeySkills, startGettingKeySkillsData } from "../constants/key_skills_constants"



export const  getAllKeySkills = (dispatch,token,navigate) =>{
    dispatch({
       type:startGettingKeySkillsData,        
   })
   getKeySkillsApi(token,dispatch,navigate).then((e)=>{
    // displaySuccessToast(e.data.message[0])
     return dispatch({
         type:saveKeySkills,
         payload:e.data.keySkills ? e.data.keySkills : []
         
     })
 })
}


export const getAllKeySkillById = (id,token,dispatch,navigate) => {
    getKeySkillByIdApi(id,token,dispatch,navigate).then((e)=>{
        return e
    })
}


