import {
    deleteSingleCanditateData,
  modifySingleCanditateData,
  saveCandidates,
  startGettingCandidatesData
} from '../../../constants/candidates_constants'
import { logoutUser } from '../../../constants/common_constants'

const INITIAL_STATE = {
  isLoading: true,
  candidates: []
}

const candidatesReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action

  switch (type) {
    case saveCandidates:
      return {
        ...state,
        isLoading: false,
        candidates: payload.filter((e, i) =>e.isDeleted !== true)
      }
    case startGettingCandidatesData:
      return {
        ...state,
        isLoading: true
      }
    case logoutUser:
      return {
        ...state,
        candidates: [],
        isLoading: true
      }

    case modifySingleCanditateData:
      let findObj = state.candidates.find((e, i) => e._id === payload._id)
      let findInd = state.candidates.indexOf(findObj)
      // let newCandidatesArray = state.candidates
      state.candidates[findInd] = payload
      return {
        ...state,
        candidates: [...state.candidates],
        isLoading: false
      }
    case deleteSingleCanditateData:
      let newDataArray = state.candidates.filter(
        (e, i) => e._id !== payload && e.isDeleted !== true
      )
      return {
        ...state,
        candidates: newDataArray,
        isLoading: false
      }
    default:
      return state
  }
}

export default candidatesReducer
