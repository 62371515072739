import * as React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import { MdOutlineClose } from 'react-icons/md'
import { ToastContainer } from 'react-toastify'
import { TextField } from '@mui/material'
import { allStyles } from '../../allStyles'

import { formatDateForExtendValidity } from '../../helper/date_format_function'
import dateFormat from "dateformat";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}))

const BootstrapDialogTitle = props => {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <MdOutlineClose />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired
}

export const ExtendPremiumValidity = ({
  open,
  closeClickHandle,
  editState,
  submitDetails
}) => {

  const [formData, setformData] = React.useState({})
  const [submitButtonDisabled, setSubmitButtonDisabled] = React.useState(true)

  React.useEffect(() => {
    setSubmitButtonDisabled(true)
    setformData(null)
  }, [])

  const closeHandle = () => {
    closeClickHandle()
    setSubmitButtonDisabled(true)
  }

  const submitDataHandle = e => {
    e.preventDefault()
    formData.id = editState.id
    submitDetails(formData)
    closeClickHandle()
  }

  const onChangeHandle = e => {
    let name = e.target.name
    let val = e.target

    const setdate = new Date(e.target.value);

    let formatedForDb = dateFormat(setdate, "dd mmm yyyy")
    setformData({ ...formData, [name]: formatedForDb })
    setSubmitButtonDisabled(false)
    
  }

  return (
    <div>
      <BootstrapDialog aria-labelledby='customized-dialog-title' open={open}>
        <BootstrapDialogTitle
          id='customized-dialog-title'
          onClose={closeHandle}
        >
          Extend Validity
        </BootstrapDialogTitle>
        <form
          autoComplete='off'
          encType='multipart/form-data'
          onSubmit={e => submitDataHandle(e)}
        >
          <DialogContent dividers sx={{ maxHeight: '700px', minWidth: 500 }}>
            {editState && (

                // <input defaultValue="2021-12-31" type="date" min="2021-12-31" />
              <TextField
                name='SubscriptionValidity'
                sx={allStyles.modalFields}
                label='Subscription Validity'
                defaultValue={editState ? formatDateForExtendValidity(editState.SubscriptionValidity) : ''}
                fullWidth
                id='fromDate'
                type='date'
                onChange={e => onChangeHandle(e)}
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{
                  inputProps: {
                    min: editState ? formatDateForExtendValidity(editState.SubscriptionValidity) : ''
                  }
                }}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button
              disabled={submitButtonDisabled}
              type='submit'
              sx={{...allStyles.actionButtons, mx: 2, my: 1 }}
              variant='outlined'
            >
              Extend Validity
            </Button>
          </DialogActions>
        </form>
      </BootstrapDialog>
      <ToastContainer />
    </div>
  )
}
