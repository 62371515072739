import React from 'react'
import './App.css'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { allStyles } from './allStyles'
import { Provider } from 'react-redux'
import store from './redux/store'
import {RouterApp} from './router/routes'
import 'react-toastify/dist/ReactToastify.css';


const theme = createTheme(allStyles.muiTheme)

function App () {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <RouterApp />
      </Provider>
    </ThemeProvider>
  )
}

export default App
