import React from 'react'
import { Button } from '@mui/material';
import { MdOutlineLogout } from 'react-icons/md';
import { allStyles } from '../../allStyles';


export const LogoutButton = ({onClickHandle}) => {
  return <Button onClick={onClickHandle} sx={allStyles.logoutButton} endIcon={<MdOutlineLogout /> }>
  Logout
</Button>
};
