import { getQualificationByIdApi, getQualificationsApi } from "../../api/qualificationsApi"
import { saveQualifications, startGettingQualificationsData } from "../constants/qualifications_constants"

export const  getQualifications = (dispatch,token,navigate) =>{
    dispatch({
       type:startGettingQualificationsData,        
   })
   getQualificationsApi(token,dispatch,navigate).then((e)=>{

    // displaySuccessToast(e.data.message[0])
     return dispatch({
         type:saveQualifications,
         payload:e.data.Qualifications ? e.data.Qualifications : []
     })
 })
}


export const getQualificationById = (id,token,dispatch,navigate) =>{
    getQualificationByIdApi(id,token,dispatch,navigate).then((e)=>{
        return e.data.Qualification[0]
    })
}

