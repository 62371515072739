import { apiHandle } from "./apiHandler"


// Get All Employers Api 
export const getEmployersApi = async (token,dispatch,navigate) => {
    return await apiHandle(token,dispatch,navigate).get('/employers')
}


// Get Employer Data By ID Api 
export const getEmployerByIdApi = async (token,id,dispatch,navigate) => {
    return await apiHandle(token,dispatch,navigate).get(`/employer?id=${id}`)
}


// Get Employer Data By ID Api 
export const updateEmployerSubValidityApi = async (token,id,handleEmployerSubcriptionData,dispatch,navigate) => {
    return await apiHandle(token,dispatch,navigate).put(`/updateEmployerSubscriptionValidity?id=${id}`,handleEmployerSubcriptionData)
}


// Approve Employer Api 
export const approveEmployerApi = async (token,id,dispatch,navigate) => {
    return await apiHandle(token,dispatch,navigate).put(`/approveEmployer?id=${id}`)
}

// Suspend Employer Api 
export const suspendEmployerApi = async (token,id,handleSuspendData,dispatch,navigate) => {
    return await apiHandle(token,dispatch,navigate).put(`/suspendEmployer?id=${id}`,handleSuspendData)
}

// Temporary Suspend Employer Api 
export const tempSuspendEmployerApi = async (token,id,handleTempSuspendData,dispatch,navigate) => {
    return await apiHandle(token,dispatch,navigate).put(`/temporarilySuspendEmployer?id=${id}`,handleTempSuspendData)
}

// Temporary Suspend Employer Api 
export const deleteEmployerApi = async (token,id,dispatch,navigate) => {
    return await apiHandle(token,dispatch,navigate).put(`/deleteEmployer?id=${id}`)
}

