import React, { useEffect, useState } from 'react'
import {
  Box,
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Paper,
  TablePagination,
  IconButton,
  Button
} from '@mui/material'
import { TableBodyDataSkeleton } from '../../assets/TableBodyDataSkeleton'
import { PaginationSkeleton } from '../../assets/PaginationSkeleton'
import { RefressButton } from '../../assets/buttons/RefressButton'
import { AddButton } from '../../assets/buttons/AddButton'
import { useDispatch, useSelector } from 'react-redux'
import { RiDeleteBin6Line, RiEdit2Line } from 'react-icons/ri'
import { ToastContainer } from 'react-toastify'
import { getAllKeySkills } from '../../redux/actions/keySkillsActions'
import { allStyles } from '../../allStyles'
import { AddKeySkill } from './AddKeySkill'
import { deleteKeySkillApi } from '../../api/keySkillsApi'
import { displayErrorToast, displaySuccessToast } from '../../helper/toast_notification_function'
import { DeleteConfirmationModal } from '../../components/DeleteConfirmationModal'
import { useNavigate } from 'react-router-dom'
import { NoDataSetMessage } from '../../components/NoDataSetMessage'



const columns = [
  { id: 'serialNumber', label: 'S#', minWidth: 20, align: 'center',sortAction:false },
  {
    id: 'KeySkill',
    label: 'Key Skill',
    minWidth: 400,
    align: 'center',
    sortAction:true
  },
  {
    id: 'actions',
    label: 'Actions',
    minWidth: 200,
    align: 'center',
    sortAction:false
    // format: value => value.toFixed(2)
  }
]


export const KeySkills = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [open, setOpen] = useState(false)

  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(true)
  const [orderData, setOrderData] = useState('ASC')

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const [deleteModal, setDeleteModal] = useState(false)
  const [selectedDataForDelete, setSelectedDataForDelete] = useState(null)

  const [selectedDataForEdit, setSelectedDataForEdit] = useState(null)

  const allKeySkill = useSelector(state => state.keySkillsReducer)
  const accessToken = useSelector(state => state.userTokensReducer.tokens.accessToken)

  const sortingColumns = col => {
    if (orderData === 'ASC') {
      const sorted = [...rows].sort((a, b) =>
        a[col].toLowerCase() >
        b[col].toLowerCase()
          ? 1
          : -1
      )
      // setLoading(true)
      setRows([...sorted])
      // setLoading(false)
      setOrderData('DSC')
    }
    else {
      const sorted = [...rows].sort((a, b) =>
        a[col].toLowerCase() <
        b[col].toLowerCase()
          ? 1
          : -1
      )
      setRows([...sorted])
      setOrderData('ASC')
    }
  }

  useEffect(() => {
    setLoading(allKeySkill.isLoading)
    setRows(allKeySkill.keySkills)
  }, [])

  useEffect(() => {
    setLoading(allKeySkill.isLoading)
    setRows(allKeySkill.keySkills)
  }, [allKeySkill])



  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  // Modal open handle through add button
  const addHandle = () => {
    setOpen(true)
  }

  // Modal Close Handle through modal cross button
  const closeClickHandle = () => {
    

    if (selectedDataForEdit) {
      setSelectedDataForEdit(null)
    }

    setOpen(false)
  }

  const refreshHandle = () => {
    getAllKeySkills(dispatch,accessToken,navigate)
  }

  const editHandle = selectedData => {
    setSelectedDataForEdit(selectedData)
    setOpen(true)
  }

  const deleteHandle = selectedData => {

    setSelectedDataForDelete(selectedData)
    setDeleteModal(true)
  }

  const confirmDeteHandle = () => {
    deleteKeySkillApi(selectedDataForDelete._id,accessToken,dispatch,navigate)
      .then(() => {
        displaySuccessToast(`${selectedDataForDelete.KeySkill} deleted successfully!`)
        getAllKeySkills(dispatch, accessToken,navigate)
        setSelectedDataForDelete(null)
        setDeleteModal(false)
      })
      .catch(error => {
        displayErrorToast(error.response.data.message[0])
        setSelectedDataForDelete(null)
        setDeleteModal(false)
      })
  }

  const cancelDeleteHandle = () => {
    setSelectedDataForDelete(null)
    setDeleteModal(false)
  }


  return <Box>
  <Stack
    sx={{ px: 1, mb: 2 }}
    direction='row'
    alignItems='center'
    justifyContent='space-between'
  >
    <Stack>
      <Typography variant='h4'>Key Skills</Typography>
    </Stack>

    <Stack spacing={3} direction='row' alignItems='center'>
      <RefressButton disabled={loading} onClickHandle={refreshHandle} />
      <Stack alignItems={'center'}>
        <AddButton onClickHandle={addHandle} caption='Key Skill' />
      </Stack>
    </Stack>
  </Stack>

  <Stack>
    <Paper sx={{ width: '100%' }}>
      <TableContainer sx={{ maxHeight: 650 }}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column, i) => (
                <TableCell

                  key={i}
                  align={column.align}
                  style={{...allStyles.tableHeadCells,width: column.minWidth}}
                >
                  {column.sortAction && !loading ? (
                    <Button sx={{textTransform:'none'}} onClick={() => sortingColumns(column.id)}>
                      {column.label}
                    </Button>
                  ) : (
                    column.label
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          {loading ? (
            <TableBodyDataSkeleton numberOfColumns={columns.length} />
          ) : rows.length !==0 ? (
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => {
                  return (
                    <TableRow hover role='checkbox' tabIndex={-1} key={i}>
                      {columns.map((column, ind) => {
                        const value = row[column.id]
                        return column.id === 'serialNumber' ? (
                          <TableCell key={ind} align={column.align}>
                            {page < 1
                              ? i + 1
                              : page * rowsPerPage + (i + 1)}
                          </TableCell>
                        ) : column.id === 'actions' ? (
                          <TableCell key={ind} align={column.align}>
                            <Stack
                              direction='row'
                              spacing={2}
                              justifyContent='center'
                            >
                              <IconButton
                                onClick={() => editHandle(row)}
                                aria-label='edit'
                              >
                                <RiEdit2Line color='#70db70' />
                              </IconButton>

                              <IconButton
                                onClick={() => deleteHandle(row)}
                                aria-label='delete'
                              >
                                <RiDeleteBin6Line color='#fb8484' />
                              </IconButton>
                            </Stack>
                          </TableCell>
                        ) : (
                          <TableCell key={ind} align={column.align}>
                            {value}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
            </TableBody>
          ): <NoDataSetMessage lengthOfColumns={columns.length} />}
        </Table>
      </TableContainer>

      {loading ? (
        <PaginationSkeleton />
      ) : (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component='div'
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  </Stack>

  <AddKeySkill
    open={open}
    closeClickHandle={closeClickHandle}
    editState={selectedDataForEdit}
    modalCaption={'Key Skill'}
  />
  <DeleteConfirmationModal
    deleteConfirmationOpen={deleteModal}
    ItemCategory={'Key Skill'}
    cancelHandle={cancelDeleteHandle}
    confirmDeteHandle={confirmDeteHandle}
    selectedData={selectedDataForDelete ? selectedDataForDelete : ''}
    title={'KeySkill'}
  />
  <ToastContainer />
</Box>
};
