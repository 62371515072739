import React, { useEffect, useState } from 'react'
import {
  Box,
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Paper,
  TablePagination,
  Button
} from '@mui/material'
import { TableBodyDataSkeleton } from '../../assets/TableBodyDataSkeleton'
import { PaginationSkeleton } from '../../assets/PaginationSkeleton'
import { RefressButton } from '../../assets/buttons/RefressButton'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { allStyles } from '../../allStyles'
import { deleteSingleJobAd, eraseSingleJobAdData, getJobAds, getSingleJobAd, modifySingleJobAd } from '../../redux/actions/jobAdsActions'
import { formatDate } from '../../helper/date_format_function'
import { actionButtonCaptions } from '../../helper/action_button_captions'
import { ViewJobAdDetails } from './ViewJobAdDetails'
import {useNavigate } from 'react-router-dom'
import { NoDataSetMessage } from '../../components/NoDataSetMessage'


const columns = [
  {
    id: 'serialNumber',
    label: 'S#',
    minWidth: 20,
    align: 'center',
    sortAction: false
  },
  {
    id: 'VacancyTitle',
    label: 'Vacancy Title',
    minWidth: 100,
    align: 'center',
    sortAction: true
  },
  {
    id: 'VacancyCategory',
    label: 'Vacancy Category',
    minWidth: 170,
    align: 'center',
    sortAction: true
  },
  {
    id: 'VacancyType',
    label: 'Vacancy Type',
    minWidth: 100,
    align: 'center',
    sortAction: true
  },
  {
    id: 'Location',
    label: 'Location',
    minWidth: 200,
    align: 'center',
    sortAction: true
  },
  {
    id: 'createdAt',
    label: 'Created Date',
    minWidth: 100,
    align: 'center',
    sortAction: true
  },
  {
    id: 'ClosingDate',
    label: 'Closing Date',
    minWidth: 100,
    align: 'center',
    sortAction: true
  },
  {
    id: 'actions',
    label: 'Actions',
    minWidth: 100,
    align: 'center',
    sortAction: false
    // format: value => value.toFixed(2)
  }
]

export const JobAds = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [open, setOpen] = useState(false)

  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(true)
  const [orderData, setOrderData] = useState('ASC')

  const allJobAds = useSelector(state => state.jobAdsReducer)

  const sortingColumns = col => {
    if (orderData === 'ASC') {
      const sorted = [...rows].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      )
      setRows([...sorted])
      setOrderData('DSC')
    } else {
      const sorted = [...rows].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      )
      setRows([...sorted])
      setOrderData('ASC')
    }
  }

  useEffect(() => {
    setLoading(allJobAds.isLoading)
    setRows(allJobAds.jobAds)
  }, [])

  useEffect(() => {
    setLoading(allJobAds.isLoading)
    setRows(allJobAds.jobAds)
  }, [allJobAds])

  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const accessToken = useSelector(state => state.userTokensReducer.tokens.accessToken)


  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }


  // Modal Close Handle through modal cross button
  const closeClickHandle = () => {
    setOpen(false)
    eraseSingleJobAdData(dispatch)
  }

  const refreshHandle = () => {
    getJobAds(dispatch, accessToken,navigate)
  }

  const viewDetailsHandle = selectedData => {
    setOpen(true)
    getSingleJobAd(dispatch, accessToken, selectedData._id)
  }

  const actionClickHandle = (buttonCaption, toSetStatus, selectedRow) => {
    if (buttonCaption === 'isDeleted') {
      deleteSingleJobAd(dispatch,accessToken, selectedRow,navigate)
    } else {
      let dataForAction = selectedRow
      dataForAction[buttonCaption] = toSetStatus
      modifySingleJobAd(dispatch,accessToken,selectedRow._id,buttonCaption,dataForAction,navigate)
    }
  }

  return (
    <Box>
      <Stack
        sx={{ px: 1, mb: 2 }}
        direction='row'
        alignItems='center'
        justifyContent='space-between'
      >
        <Stack>
          <Typography variant='h4'>Job Ads</Typography>
        </Stack>

        <Stack spacing={3} direction='row' alignItems='center'>
          <RefressButton disabled={loading} onClickHandle={refreshHandle} />

        </Stack>
      </Stack>

      <Stack>
        <Paper sx={{ width: '100%' }}>
          <TableContainer sx={{ maxHeight: 650 }}>
            <Table stickyHeader aria-label='sticky table'>
              <TableHead>
                <TableRow>
                  {columns.map((column, i) => (
                    <TableCell
                      key={i}
                      align={column.align}
                      style={{
                        ...allStyles.tableHeadCells,
                        width: column.minWidth
                      }}
                    >
                      {column.sortAction && !loading ? (
                        <Button
                          disabled={loading}
                          sx={{ textTransform: 'none' }}
                          onClick={() => sortingColumns(column.id)}
                        >
                          {column.label}
                        </Button>
                      ) : (
                        column.label
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              {loading ? (
                <TableBodyDataSkeleton numberOfColumns={columns.length} />
              ) : rows.length !==0 ?  (
                <TableBody>
                  {rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => {
                      return (
                        <TableRow hover role='checkbox' tabIndex={-1} key={i}>
                          {columns.map((column, ind) => {
                            const value = row[column.id]
                            return column.id === 'serialNumber' ? (
                              <TableCell key={ind} align={column.align}>
                                {page < 1
                                  ? i + 1
                                  : page * rowsPerPage + (i + 1)}
                              </TableCell>
                            )  : column.id === 'createdAt' ? (
                              <TableCell key={ind} align={column.align}>
                                {formatDate(value)}
                              </TableCell>
                            ) : column.id === 'ClosingDate' ? (
                              <TableCell key={ind} align={column.align}>
                                {formatDate(value)}
                              </TableCell>
                            ) : column.id === 'actions' ? (
                              <TableCell key={ind} align={column.align}>
                                <Stack
                                  direction='row'
                                  spacing={3}
                                  justifyContent='start'
                                  alignItems='center'
                                >
                                  <Stack direction='row' spacing={3}>
                                    <Button
                                      variant='outlined'
                                      color='info'
                                      sx={allStyles.actionButtons}
                                      onClick={() => viewDetailsHandle(row)}
                                      // aria-label='viewDetails'
                                    >
                                     {actionButtonCaptions.viewDetailsButton}
                                    </Button>
                                    <Button
                                      variant='contained'
                                      color='error'
                                      sx={allStyles.actionButtons}
                                      onClick={() =>
                                        actionClickHandle(
                                          'isDeleted',
                                          true,
                                          row
                                        )
                                      }
                                    >
                                      {actionButtonCaptions.deleteButton}
                                    </Button>
                                  </Stack>

                                  <Stack
                                    direction='row'
                                    spacing={3}
                                    sx={{ mx: 2, my: 1 }}
                                  >
                                    {row.isSuspended ? (
                                      <Button
                                        sx={allStyles.actionButtons}
                                        variant='contained'
                                        color='info'
                                        onClick={() =>
                                          actionClickHandle(
                                            'isSuspended',
                                            false,
                                            row
                                          )
                                        }
                                      >
                                        {actionButtonCaptions.activateSuspend}
                                      </Button>
                                    ) : (
                                      <Stack direction='row' spacing={3}>
                                        <Button
                                          sx={allStyles.actionButtons}
                                          variant='contained'
                                          color='info'
                                          onClick={() =>
                                            actionClickHandle(
                                              'isSuspended',
                                              true,
                                              row
                                            )
                                          }
                                        >
                                          {actionButtonCaptions.suspendButton}
                                        </Button>
                                        {row.isTemporarySuspended ? (
                                          <Button
                                            sx={allStyles.actionButtons}
                                            variant='outlined'
                                            color='warning'
                                            onClick={() =>
                                              actionClickHandle(
                                                'isTemporarySuspended',
                                                false,
                                                row
                                              )
                                            }
                                          >
                                            {actionButtonCaptions.activateTempSuspend}
                                          </Button>
                                        ) : (
                                          <Button
                                            sx={allStyles.actionButtons}
                                            variant='contained'
                                            color='warning'
                                            onClick={() =>
                                              actionClickHandle(
                                                'isTemporarySuspended',
                                                true,
                                                row
                                              )
                                            }
                                          >
                                            {actionButtonCaptions.tempSuspendButton}
                                          </Button>
                                        )}
                                      </Stack>
                                    )}
                                  </Stack>
                                </Stack>
                              </TableCell>
                            ) : (
                              <TableCell key={ind} align={column.align}>
                                {value}
                              </TableCell>
                            )
                          })}
                        </TableRow>
                      )
                    })}
                </TableBody>
              ) :<NoDataSetMessage lengthOfColumns={columns.length} />}
            </Table>
          </TableContainer>

          {loading ? (
            <PaginationSkeleton />
          ) : (
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component='div'
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Paper>
      </Stack>
      <ViewJobAdDetails
        open={open}
        closeClickHandle={closeClickHandle}
      />
      <ToastContainer />
    </Box>
  )
}
