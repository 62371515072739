import { Divider, Stack, Typography } from '@mui/material'
import React from 'react'
import { allStyles } from '../allStyles'

export const ViewDetailsMainHeadings = ({ headingCaption }) => {
  return (
    <Stack
      justifyContent='center'
      direction='column'
      sx={{ mt: 4,mb:2,position: 'relative'  }}
    >
      <Stack
    justifyContent='center'
        sx={{
          position: 'absolute',
          backgroundColor: 'white',
          zIndex: 'tooltip',
          paddingRight: '20px'
        }}
      >
        <Typography variant='h6' sx={allStyles.viewDetailsMainHeadings}>
          {headingCaption}
        </Typography>
      </Stack>
      <Divider />
    </Stack>
  )
}
