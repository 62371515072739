import { CircularProgress, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'

export const RenderQualification = ({ qualificationId }) => {
  const [responseData, setResponseData] = useState('')
  const [loading, setLoading] = useState(true)

  const accessToken = useSelector(state => state.userTokensReducer.tokens.accessToken)
  const allQualifications = useSelector(
    state => state.qualificationsReducer.qualifications
  )

  useEffect(() => {

    if(allQualifications){
        const findQualification = allQualifications.find(
            e => e._id === qualificationId
          )
          if (!findQualification) {
            // getQualificationByIdApi(qualificationId, accessToken)
            //   .then(e => {
            //     setResponseData(e.data.Qualification[0].Qualification)
            //     setLoading(false)
            //   })
            //   .catch(err => {
            //     if (err.message === 'Request failed with status code 404') {
                  setResponseData('---')
                  setLoading(false)
            //     } else {
            //     }
            //   })
          }
          else {
              setResponseData(findQualification.Qualification)
              setLoading(false)
          }
    }
   
  }, [qualificationId])

  return (
    <>
      {loading ? <CircularProgress /> : <Typography>{responseData}</Typography>}
    </>
  )
}
