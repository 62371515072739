import * as React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { ToastContainer } from 'react-toastify'
import { ViewDetailsMainHeadings } from '../../components/ViewDetailsMainHeadings'
import {
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography
} from '@mui/material'
import { allStyles } from '../../allStyles'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useState } from 'react'
import { LoadingForData } from '../../assets/LoadingForData'
import { formatDate } from '../../helper/date_format_function'
import { MdOutlineClose } from 'react-icons/md'
import { deleteSingleEmployer, modifySingleEmployer } from '../../redux/actions/employersActions'
import { useNavigate } from 'react-router-dom'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}))

let organizationInfo = [
  {
    caption: 'Registered User Name:',
    id: 'Username',
    col:6
  },
  {
    caption: 'Organization Name:',
    id: 'OrganizationName',
    col:6
  },
  {
    caption: 'Website:',
    id: 'Website',
    col:6
  },
  {
    caption: 'STRN',
    id: 'STRN',
    col:6
  },
  {
    caption: 'Organization Email:',
    id: 'organizationEmail',
    col:6
  },
  {
    caption: 'Organization Phone Number:',
    id: 'organizationPhoneNumber',
    col:6
  },
  {
    caption: 'City:',
    id: 'City',
    col:6
  },
  {
    caption: 'Communication Address:',
    id: 'communicationAddress',
    col:12
  },
]



let contactPersonInfo = [
  {
    caption: 'First Name:',
    id: 'FirstName',
    col:6
  },
  {
    caption: 'Last Name:',
    id: 'FirstName',
    col:6
  },
  {
    caption: 'Email:',
    id: 'Email',
    col:6
  },
  {
    caption: 'Phone Number:',
    id: 'PhoneNumber',
    col:6
  },
]





let applicationStatus = [
  {
    caption: 'Approved:',
    id: 'isApproved'
  },
  {
    caption: 'Deleted:',
    id: 'isDeleted'
  },
  {
    caption: 'Suspended:',
    id: 'isSuspended'
  },
  {
    caption: 'Temporary Suspended:',
    id: 'isTemporarySuspended'
  },
  {
    caption: 'Subscription Type:',
    id: 'subscriptionType'
  },
  {
    caption: 'Suspended Date:',
    id: 'suspendedDate'
  },
  {
    caption: 'temporarysuspendedDate:',
    id: 'temporarysuspendedDate'
  },
  {
    caption: 'Subscription Validity:',
    id: 'SubscriptionValidity'
  },
]

const BootstrapDialogTitle = props => {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <MdOutlineClose />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired
}

export const ViewEmployerDetails = ({
  open,
  closeClickHandle
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [dataToView, setDataToView] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const accessToken = useSelector(state => state.userTokensReducer.tokens.accessToken)
  const selectedRowData = useSelector(state => state.singleEmployerReducer)


  const closeHandle = () => {
    closeClickHandle()
  }

  useEffect(() => {
    setDataToView(selectedRowData.singleEmployer)
    setIsLoading(selectedRowData.isLoading)
  }, [selectedRowData])

  const actionClickHandle = (buttonCaption, toSetStatus) => {
    if (buttonCaption === 'isDeleted') {
    deleteSingleEmployer(dispatch, accessToken, dataToView)
    closeClickHandle()
    } else {
      dataToView[buttonCaption] = toSetStatus
      modifySingleEmployer(
        dispatch,
        accessToken,
        dataToView._id,
        buttonCaption,
        dataToView,
        navigate
      )
    }
  }

  return (
    <div>
      <BootstrapDialog
        maxWidth='xl'
        aria-labelledby='customized-dialog-title'
        open={open}
      >
        <BootstrapDialogTitle
          id='customized-dialog-title'
          onClose={closeHandle}
        >
        Employer Details
        </BootstrapDialogTitle>
        <DialogContent dividers sx={{ maxHeight: '720px', minWidth: 1100 }}>
          {isLoading ? (
            <LoadingForData />
          ) : (
            <Stack spacing={3}>
              <Stack spacing={1}>
                <ViewDetailsMainHeadings headingCaption='Organization Information' />

                <Grid container>
                  {organizationInfo.map((e, i) => (
                    <Grid key={i} item md={e.col} lg={e.col} xl={e.col} sx={{ mb: 3 }}>
                      <Stack direction='row' spacing={2}>
                        <Typography sx={{ fontWeight: 'bold' }}>
                          {e.caption}
                        </Typography>
                        <Typography>{dataToView[e.id]}</Typography>
                      </Stack>
                    </Grid>
                  ))}
                </Grid>
              </Stack>


              <Divider />

              
              <Stack spacing={1}>
                <ViewDetailsMainHeadings headingCaption='Contact Person Details' />

                <Grid container>
                  {contactPersonInfo.map((e, i) => (
                    <Grid key={i} item md={e.col} lg={e.col} xl={e.col} sx={{ mb: 3 }}>
                      <Stack direction='row' spacing={2}>
                        <Typography sx={{ fontWeight: 'bold' }}>
                          {e.caption}
                        </Typography>
                        <Typography>{dataToView.contactPerson[e.id]}</Typography>
                      </Stack>
                    </Grid>
                  ))}
                </Grid>
              </Stack>



              <Divider />

              <Stack spacing={2}>
                <ViewDetailsMainHeadings headingCaption='Application Status' />

                <Grid container>
                  {applicationStatus.map((e, i) => (
                    <Grid key={i} item md={4} lg={4} xl={4} sx={{ mb: 3 }}>

                      {e.id === 'SubscriptionValidity' || e.id === 'temporarysuspendedDate' || e.id === 'suspendedDate'  ? <Stack direction='row' spacing={2}>
                        <Typography sx={{ fontWeight: 'bold' }}>
                          {e.caption}
                        </Typography>
                        <Typography>
                          {formatDate(dataToView[e.id])}
                        </Typography>
                      </Stack> : <Stack direction='row' spacing={2}>
                        <Typography sx={{ fontWeight: 'bold' }}>
                          {e.caption}
                        </Typography>
                        <Typography>
                          {dataToView[e.id] === false ? 'No' : dataToView[e.id] === true ? 'Yes' : dataToView[e.id]}
                        </Typography>
                      </Stack>}
                      
                    </Grid>
                  ))}
                </Grid>
              </Stack>
            </Stack>
          )}
        </DialogContent>
        <DialogActions>
          {dataToView && (
            <Stack direction='row' spacing={3} sx={{ mx: 2, my: 1 }}>
              <Button
                    sx={allStyles.actionButtons}
                    variant='contained'
                    color='error'
                    onClick={() => actionClickHandle('isDeleted', true)}
                  >
                    Delete
                  </Button>
              {dataToView.isApproved ? (
                <Stack direction='row' spacing={3} sx={{ mx: 2, my: 1 }}>
                  {dataToView.isSuspended ? (
                    <Button
                      sx={allStyles.actionButtons}
                      variant='contained'
                      color='info'
                      onClick={() => actionClickHandle('isSuspended', false)}
                    >
                      Activate Suspended
                    </Button>
                  ) : (
                    <Stack direction='row' spacing={3}>
                      <Button
                        sx={allStyles.actionButtons}
                        variant='contained'
                        color='info'
                        onClick={() => actionClickHandle('isSuspended', true)}
                      >
                        Suspend
                      </Button>
                      {dataToView.isTemporarySuspended ? (
                        <Button
                          sx={allStyles.actionButtons}
                          variant='contained'
                          color='warning'
                          onClick={() =>
                            actionClickHandle('isTemporarySuspended', false)
                          }
                        >
                          Activate Temporary Suspended
                        </Button>
                      ) : (
                        <Button
                          sx={allStyles.actionButtons}
                          variant='contained'
                          color='warning'
                          onClick={() =>
                            actionClickHandle('isTemporarySuspended', true)
                          }
                        >
                          Temporary Suspend
                        </Button>
                      )}
                    </Stack>
                  )}
                </Stack>
              ) : (
                <Stack >
                  <Button
                    sx={allStyles.actionButtons}
                    variant='contained'
                    color='success'
                    onClick={() => actionClickHandle('isApproved', true)}
                  >
                    Approve
                  </Button>
                </Stack>
              )}
            </Stack>
          )}
        </DialogActions>
      </BootstrapDialog>
      <ToastContainer />
    </div>
  )
}

