import { logoutUser} from "../../constants/common_constants"
import { saveQualifications, startGettingQualificationsData } from "../../constants/qualifications_constants"


const INITIAL_STATE = {
    isLoading:true,
    qualifications:[],
}


const qualificationsReducer = (state = INITIAL_STATE,action) => {

    const {type, payload} = action

    switch (type) {
        case saveQualifications:
            return {
                ...state,
                isLoading:false,
                qualifications:payload
            }
        case startGettingQualificationsData:
            return {
                ...state,
                isLoading:true,
            }
        case logoutUser:
            return {
                ...state,
                qualifications:[],
                isLoading:true,
            }
        default:
            return state
    }
}


export default qualificationsReducer